import http from '@/utils/request'
// 产运需运力合同模板(列表)
export const findContractTemplateList = (data) => http.post("/trade/submission/findContractTemplateList", data)
//添加编辑合同模版
export const addAndEditContractTemplate = (data) => http.post("/trade/submission/addAndEditContractTemplate", data)
//详情
export const getContractTemplateInfo = (id) => http.post("/trade/submission/getContractTemplateInfo?id="+id)
//删除合同模板
export const delContractTemplate = (data) => http.post("/trade/submission/delContractTemplate", data)
//合同(列表)
export const findContractList = (data) => http.post("/trade/submission/findContractList", data)
//合同删除
export const delContract = (data) => http.post("/trade/submission/delContract", data)
//查询出卖人
export const userList = (data) => http.post("/system/sysUser/findUserList", data)
//获取产品规格
export const findVarietiesAndType = (data) => http.post("/trade/submissionManage/findVarietiesAndType?type="+data)
//录入并提交
export const addAndEditContract = (data) => http.post("/trade/submission/addAndEditContract", data)
//确定驳回合同
export const editContractStatus = (data) => http.post("/trade/submission/editContractStatus", data)
//查看报送数据
export const getSubmitted = (id) => http.post("/trade/submission/getSubmitted?id="+id)
//推送数据
export const addSubmitted = (data) => http.post("/trade/submission/addSubmitted", data)
// 企业类型认证
export const addAndEditEnterpriseAuth = (data) => http.post("/trade/submission/addAndEditEnterpriseAuth", data)
//获取企业是否已认证
export const getEnterpriseAuthInfo = (data) => http.post("/trade/submission/getEnterpriseAuthInfo", data)
// 上传营业执照
export const ossUploadFile2 = (file) => http.posttimeout('/third/third/ossUploadFile2', file)
//获取省市区
export const getTreeV2 = (data) => http.post("/system/sysRegion/getTreeV2", data)
//获取企业认证详情
export const getEnterpriseAuthInfoDetail = (data) => http.post("/trade/submission/getEnterpriseAuthInfoDetail", data)
//获取合同详情
export const getContractInfo = (data) => http.post("/trade/submission/getContractInfo?id="+data)
//分月煤炭计算
export const executeMonthCount = (data) => http.post("/trade/submission/executeMonthCount", data)
//获取文件模版ID
export const getFileIdByType = (data) => http.post("/trade/submission/getFileIdByType", data)
//履约合同上传
export const editContractFile = (data) => http.post("/trade/submission/editContractFile", data)
//获取煤矿列表
export const findCoalMineList = (data) => http.post("/trade/submission/findCoalMineList", data)
//导出
export const exportContractList = (data) => http.postquerys("/trade/submission/exportContractList", data)
//中长期资料
export const queryList = (data) => http.post("/info/article/queryList", data)
//详情
export const query = (data) => http.post("/info/article/query", data)
//获取字典分类
export const getSysDictDataByByDictType = (data) => http.post("/system/sysDictData/getSysDictDataByByDictType", data)
//导出月度履约数据
export const exportSubmission = (data) => http.postquerys("/trade/submission/exportSubmission", data)

export const importSubmission = (data) => http.post('/trade/submission/importSubmission', data)

export const addMiningDeclare = (data) => http.post('/system/sysMining/addMiningDeclare', data)

export const listMiningDeclare = (data) => http.post('/system/sysMining/listMiningDeclare', data)

export const applyMiningAuth = (data) => http.post('/system/sysMiningAuth/applyMiningAuth', data)


// 获取审核绑定关联销售列表
export const findEnterpriseBindingList = (data) => http.post("/trade/submission/findEnterpriseBindingList", data)
// 添加编辑绑定销售燃料公司




// 基础信息
// 详情
export const detailMiningAuthInfo = (data) => http.post("/system/sysMiningAuthInfo/detailMiningAuthInfo", data)
// 提交
export const applyMiningAuthInfo = (data) => http.post("/system/sysMiningAuthInfo/applyMiningAuthInfo", data)
// 
export const pageInfo = (data) => http.post("/system/coalMineProduct/pageInfo", data)

//获取合同编号
export const getContractNo = (data) => http.post("/trade/submission/getContractNo", data)
// 添加编辑绑定销售燃料公司
export const addAndEditEnterpriseBinding = (data) => http.post("/trade/submission/addAndEditEnterpriseBinding", data)


// 获取全部企业信息
export const getAllEnterprise = (data) => http.post("/system/sysUser/getAllEnterprise", data)