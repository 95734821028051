<template>
  <div class="userCenterMain">
    <Left></Left>
    <div class="right">
      <router-view />
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  export default {

    components: {
      Left: () => import('./components/left.vue')
    },
    created() {
      let routerArr = ['/manage', '/myAssets', '/jiaoyi', '/finance','/Mining', '/released', '/participation', '/purchase', '/take', '/purchasehangtag', '/markethangtag', '/order', '/personnelStructure', '/record', '/guanzhu', '/qianzhang', '/contract', '/myPublished', '/financeTax', '/banking', '/logistics', '/warehouse', '/science', '/usmyPublished', '/usfinanceTax', '/usbanking', '/uslogistics', '/uswarehouse', '/usscience']
      for (let i = 0; i < routerArr.length; i++) {
        if (this.$route.path == routerArr[i]) {
          Vue.ls.set("active", i + 1);
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .userCenterMain {
    width: 1196px;
    margin: 0 auto;
    display: flex;

    .right {
      width: 970px;
    }
  }
</style>