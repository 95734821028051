<template>
  <div class="medium">
      <Left></Left>
      <div class="right">

      </div>
  </div>
</template>

<script>
export default {
components: {
    Left: ()=> import('./components/left1.vue')
  },
}
</script>

<style lang="scss" scoped>
.medium{
  width: 1196px;
  margin: 0 auto;
  display: flex;
  .right{
    width: 970px;
  }
}
</style>