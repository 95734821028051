<template>
  <div class="certification">
    <Left></Left>
    <div class="right">
      <div class="content">
        <div class="no" v-if="istrure && !listobg.whetherAuth">
          <div class="title">2023年中长期合同企业类型认证</div>
          <div class="authentication">
            您还没有进行企业类型认证,<span
              class="cer"
              @click="authenticationClick"
              >立即认证</span
            >
          </div>
        </div>

        <certifi
          @transfer="getUser"
          @GBsfer="GBsfer"
          :listall="listobg"
          v-if="!istrure && !listobg.whetherAuth"
        ></certifi>
        <div v-if="listobg.whetherAuth">
          <div class="title">2023年中长期合同企业类型认证</div>
          <div class="titleType">{{ tableData[0].typeLabel }}</div>
          <el-table :data="tableData" border>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  :disabled="scope.row.statusLabel =='审核中'||scope.row.statusLabel =='已通过'"
                  @click="handleClicks(scope.row)"
                  type="text"
                  size="small"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="statusLabel"
              label="审核状态"
              width="150"
              align="center"
              v-if="tableData[0].statusLabel"
            >
            <template slot-scope="scope">
                <el-button
                  @click="rejectClicks(scope.row)"
                  type="text"
                  size="small"
                  >{{tableData[0].statusLabel}}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="typeLabel"
              label="企业类型"
              align="center"
              width="150"
              v-if="tableData[0].typeLabel"
            >
            </el-table-column>
            <el-table-column
              prop="classesLabel"
              label="企业类别"
              align="center"
              width="150"
              v-if="tableData[0].classesLabel"
            >
            </el-table-column>

            <el-table-column
              prop="natureLabel"
              label="企业性质"
              width="150"
              align="center"
              v-if="tableData[0].natureLabel"
            >
            </el-table-column>
            <el-table-column
              prop="afterPlantOutput"
              label="2023年计划产量"
              align="center"
              width="150"
              v-if="tableData[0].afterPlantOutput"
            >
            </el-table-column>
            <el-table-column
              prop="afterYearImportCount"
              label="2023年进口煤炭预测用量"
              width="200"
              align="center"
              v-if="tableData[0].afterYearImportCount"
            >
            </el-table-column>
            <el-table-column
              prop="afterYearInternalCount"
              label="2023年国内煤炭预测用量"
              width="200"
              align="center"
              v-if="tableData[0].afterYearInternalCount"
            >
            </el-table-column>
            <!-- <el-table-column
              prop="auditTime"
              label="审核时间"
              align="center"
              width="150"
              v-if="tableData[0].statusLabel"
            >
            </el-table-column> -->
            <el-table-column
              prop="beforeYearCount"
              label="2021年9月之后新增核定产能"
              width="200"
              align="center"
              v-if="tableData[0].beforeYearCount"
            >
            </el-table-column>
            <el-table-column
              prop="currentYearCount"
              label="2022当年用煤总量"
              width="150"
              align="center"
              v-if="tableData[0].currentYearCount"
            >
            </el-table-column>
            <el-table-column
              label="营业执照"
              align="center"
              v-if="tableData[0].licenseUrl"
            >
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row.licenseUrl,'1')"
                  type="text"
                  size="small"
                  >查看</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="miningLicenceNo"
              label="采矿许可证号"
              width="150"
              align="center"
              v-if="tableData[0].miningLicenceNo"
            >
            </el-table-column>
            <el-table-column
              label="采矿许可证"
              align="center"
              width="150"
              v-if="tableData[0].miningLicenceUrl"
            >
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row.miningLicenceUrl,'2')"
                  type="text"
                  size="small"
                  >查看</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="miningLicenceValidityEnd"
              label="证件有效期开始"
              width="150"
              align="center"
              v-if="tableData[0].miningLicenceValidityEnd"
            >
            </el-table-column>
            <el-table-column
              prop="miningLicenceValidityStart"
              label="证件有效期结束"
              width="150"
              align="center"
              v-if="tableData[0].miningLicenceValidityStart"
            >
            </el-table-column>
            <el-table-column
              prop="miningName"
              label="采矿权人"
              width="150"
              align="center"
              v-if="tableData[0].miningName"
            >
            </el-table-column>
            <!-- <el-table-column
              prop="name"
              label="名称"
              width="200"
              align="center"
              v-if="tableData[0].name"
            >
            </el-table-column> -->

            <el-table-column
              prop="predictedOutput"
              label="2022年预计产量"
              width="150"
              align="center"
              v-if="tableData[0].predictedOutput"
            >
            </el-table-column>

            <el-table-column
              prop="targetCount"
              label="目前总核定产"
              width="150"
              align="center"
              v-if="tableData[0].targetCount"
            >
            </el-table-column>
            <el-table-column
              prop="taskLoad"
              label="煤矿电煤签订任务量"
              width="150"
              align="center"
              v-if="tableData[0].taskLoad"
            >
            </el-table-column>
            <el-table-column
              prop="cityName"
              label="所属省市区"
              width="150"
              align="center"
              v-if="tableData[0].cityName"
            >
            </el-table-column>

            <el-table-column
              prop="whetherProductCoal"
              label="是否在产煤矿"
              width="150"
              align="center"
              v-if="tableData[0].whetherProductCoal"
            >
              <template slot-scope="scope">
                <div
                  v-if="scope.row.whetherProductCoal"
                  type="text"
                  size="small"
                >
                  是
                </div>
                <div
                  v-if="!scope.row.whetherProductCoal"
                  type="text"
                  size="small"
                >
                  否
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="whetherTracking"
              width="150"
              label="是否统调"
              align="center"
              v-if="tableData[0].whetherTracking"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.whetherTracking" type="text" size="small">
                  是
                </div>
                <div v-if="!scope.row.whetherTracking" type="text" size="small">
                  否
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <div class="diglogImg">
        <!-- <img :src="licenseUrl" alt="" /> -->
        <el-image
          style="width: 100px; height: 100px"
          :src="licenseUrl"
          :preview-src-list="srcList"
        >
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getEnterpriseAuthInfo,
  getEnterpriseAuthInfoDetail,
} from "@/api/Medium.js";
import Vue from "vue";
import * as types from "@/store/action-types";
const { baseUrl } = require("@/config/index.js");
export default {
  data() {
    return {
      baseUrl: baseUrl,
      centerDialogVisible: false,
      istrure: true,
      listobg: {},
      title:'',
      tableData: [],
      listall: {},
      licenseUrl: "",
      status: 0,
      srcList: [],
    };
  },
  mounted() {
    // this.status = Vue.ls.get(types.status);
    // console.log(this.status)
    this.EnterpriseAuthInfo();
  },
  methods: {
    rejectClicks(row){
      if(row.status==2){
        this.$message.error(row.reason);
      }
    },
    handleClick(row,val) {
      console.log(row,val);
      if(val=='1'){
        this.title='查看营业执照'
      }else if(val=='2'){ 
        this.title='查看采矿许可证'
      }
      if(row.slice(-4) == '.pdf'){
        window.open(this.baseUrl + "third/third/ossGetFile/" + row)
        return false;
      }
      this.centerDialogVisible = true;
      this.licenseUrl = this.baseUrl + "third/third/ossGetFile/" + row;
      this.srcList.push(this.baseUrl + "third/third/ossGetFile/" + row);
      
    },
    GBsfer(e) {
      this.istrure = e;
    },
    handleClicks() {
      this.istrure = false;
      this.listobg.whetherAuth = false;
    },
    getUser(msg) {
      if (msg.code == 0) {
        this.EnterpriseAuthInfo();
      }
    },
    authenticationClick() {
      this.istrure = false;
    },
    EnterpriseAuthInfo() {
      getEnterpriseAuthInfo().then((res) => {
        console.log(res, "---------------------");
        if (res.code == 0) {
          this.listall = res.data;
          this.status = res.data.status;
        }
      });
      getEnterpriseAuthInfoDetail().then((res) => {
        if (res.code == 0) {
          let list = [];
          list.push(res.data);
          this.tableData = list;
          this.listobg = res.data;
          Vue.ls.set(types.status, res.data.status);
          //  let enterpriseId = Vue.ls.get(types.enterpriseId);
        }
      });
    },
  },
  components: {
    Left: () => import("../components/left1.vue"),
    certifi: () => import("./components/certifi.vue"),
  },
};
</script>

<style lang="scss" scoped>
.diglogImg {
  text-align: center;
  img {
    width: 200px;
    // height: 100px;
  }
}
.title {
  font-size: 20px;
  text-align: center;
  margin: 20px 0 40px 0;
}
.certification {
  width: 1196px;
  margin: 0 auto;
  display: flex;
  .right {
    width: 970px;
    .content {
      padding: 20px;
      background: #fff;
      min-height: 660px;
      margin-top: 20px;
      border: 1px solid #ccc;
      .no {
        text-align: center;
        .title {
          font-size: 30px;
        }
        .authentication {
          margin-top: 50px;
          font-size: 20px;
          .cer {
            color: red;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.titleType {
  margin: 10px;
  font-size: 20px;
  font-weight: bold;
}
</style>