<template>
  <div class="confirm">
    <Left></Left>
    <div class="right">
      <div class="conter">
        <div class="Line"></div>
        <div class="flex btn">
          <el-input
            v-model="config.no"
            placeholder="请输入合同编号"
            style="margin-left: 20px"
          ></el-input>
          <el-input
            v-model="config.sellerName"
            placeholder="请输入出卖人"
            style="margin-left: 20px"
          ></el-input>
          <el-input
            v-model="config.buyerName"
            placeholder="请输入买受人"
            style="margin-left: 20px"
          ></el-input>
          <el-button
            type="primary"
            style="margin-left: 20px"
            @click="CXClick"
            :disabled="status != 3"
            >查询</el-button
          >
          <el-button type="info" @click="ResetClick" :disabled="status != 3"
            >重置</el-button
          >
        </div>
        <div class="Line"></div>
        <div>
          <div class="title">2022年度煤炭中长期合同作废列表</div>
          <div class="flex remarks">
            <div></div>
            <div style="color: #ccc">
              合同价格（单位：元/吨）、合同数量（单位：万吨）
            </div>
          </div>
        </div>

        <div style="margin: 20px auto">
          <el-table border :data="tableData" style="width: 100%">
            <el-table-column
              prop="no"
              label="合同编号"
              width="200"
              align="center"
            >
              <template slot-scope="scope">
                <div @click="NoClick(scope.row)" style="cursor: pointer">
                  {{ scope.row.no }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="合同状态"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  @click="StatusClick(scope, 1)"
                  type="text"
                  size="small"
                >
                  {{ scope.row.status | status }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="auditStatus"
              label="审核状态"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  @click="StatusClick(scope, 2)"
                  type="text"
                  size="small"
                >
                  {{ scope.row.auditStatus | auditStatus }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="wetherAbandonment"
              label="废弃状态"
              width="120"
              align="center"
            >
              已废弃
            </el-table-column>
            <el-table-column
              prop="sellerName"
              label="出卖人"
              width="250"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="buyerName"
              label="买受人"
              width="250"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="contractType"
              label="合同类型"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.contractType | contractType }}
              </template>
            </el-table-column>
            <!-- <el-table-column prop="Enter" label="是否补录	" width="120" align="center">
            </el-table-column> -->
            <el-table-column
              prop="contractForm"
              label="合同形式"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.contractForm | contractForm }}
              </template>
            </el-table-column>
            <el-table-column
              prop="basePrice"
              label="合同基础价格"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="typeOfShipping"
              label="运输方式"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.typeOfShipping | typeOfShipping }}
              </template>
            </el-table-column>
            <el-table-column
              prop="startDate"
              label="合同起始日期"
              width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="endDate"
              label="合同结束日期"
              width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="positionName"
              label="签订地点"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="departure"
              label="发局"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="arrive"
              label="到局"
              width="120"
              align="center"
            >
            </el-table-column>
          </el-table>
          <div class="pageina">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              layout="total, prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { findContractList } from "@/api/Medium.js";
import Vue from "vue";
import * as types from "@/store/action-types";
export default {
  data() {
    return {
      status: null,
      numberVal: "",
      SellerVal: "",
      BuyerVal: "",
      tableData: [],
      total: 0,
      config: {
        pageNum: 1,
        pageSize: 10,
        type: 3,
        no: "",
        sellerName: "",
        buyerName: "",
        status: null,
      },
    };
  },
  components: {
    Left: () => import("../components/left1.vue"),
  },
  mounted() {
    this.status = Vue.ls.get(types.status);
    this.getlist();
  },
  filters: {
    status(val) {
      let _data = {
        1: "需方待确认",
        2: "供方待确认",
        3: "合同确认",
        4: "需方已驳回",
        5: "供方已驳回",
      };
      return _data[val];
    },
    auditStatus(val) {
      let _data = {
        1: "待审核",
        2: "已驳回",
        3: "已通过",
      };
      return _data[val];
    },
    contractType(val) {
      let _data = {
        1: "年度中长期合同",
        2: "进口煤应急保障合同",
        3: "重点贸易商保供合同",
      };
      return _data[val];
    },
    typeOfShipping(val) {
      let _data = {
        1: "铁水联运（国铁）",
        2: "铁路直达（国铁）",
        3: "自有铁路",
        4: "公路运输",
        5: "其他运输",
      };
      return _data[val];
    },
    contractForm(val) {
      let _data = {
        1: "跨省衔接",
        2: "省内交易",
      };
      return _data[val];
    },
  },
  methods: {
    StatusClick(e, val) {
      console.log(e.row);
      if (val == 1) {
        if (e.row.reason != null&&e.row.reason !='') {
          this.$message.error(e.row.reason);
        }
      }
      if(val==2){
        if (e.row.auditReason != null&&e.row.auditReason !='') {
          this.$message.error(e.row.auditReason);
        }
      }
    },
    NoClick(row) {
      console.log(row);
      this.$router.push({
        path: "/AddEnter",
        query: {
          id: row.id,
          type: 1,
        },
      });
    },
    CXClick() {
      this.getlist();
    },
    getlist() {
      findContractList(this.config).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.list;
          console.log(res.data.total);
          this.total = res.data.total;
        }
      });
    },
    ResetClick() {
      this.config = {
        pageNum: 1,
        pageSize: 10,
        type: 3,
        no: "",
        sellerName: "",
        buyerName: "",
      };
      this.getlist();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.config.pageNum = val;
      this.getlist();
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm {
  width: 1196px;
  margin: 0 auto;
  display: flex;
  .right {
    width: 970px;
    .conter {
      width: 100%;
      border: 1px solid #ccc;
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
  }
}
.remarks {
  margin-top: 20px;
  align-items: center;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.title {
  text-align: center;
  margin: 30px auto 0;
  font-size: 20px;
}
.Line {
  width: 100%;
  height: 1px;
  background: #ccc;
}
.btn {
  margin: 20px 0;
}
.pageina {
  text-align: right;
  margin-top: 10px;
}
</style>