<template>
  <div class="submitted">
    <Left></Left>
    <div class="right">
      <div class="conter">
        <div class="Line"></div>
        <div class="title">2022年煤炭中长期合同履约数据报送界面</div>
        <div class="right_cont">执行量(单位:万吨),当月合同价格(单位:元/吨)</div>
        <div style="max-width: 941px">
          <table style="border: 1px solid gray; margin-top: 20px">
            <tr>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                中长期合同编号
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                出卖人
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                买受人
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                发货人
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                收货人
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                发站
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                到站
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                运输方式
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                合同数量
              </th>
              <th style="border-bottom: 1px solid gray">所属行业</th>
            </tr>
            <tr>
              <td style="border-right: 1px solid gray; text-align: center">
                <div>{{ details.no }}</div>
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                <div>{{ details.sellerName }}</div>
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                <div>{{ details.buyerName }}</div>
              </td>
              <td style="border-right: 1px solid gray; text-align: center">
                <div>{{ details.forwardingUnit }}</div>
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                <div>{{ details.consignee }}</div>
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                <div>{{ details.departure }}</div>
              </td>
              <td style="border-right: 1px solid gray; text-align: center">
                <div>{{ details.arrive }}</div>
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                <div>{{ details.typeOfShipping | typeOfShipping }}</div>
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                <!-- <input type="text" /> -->
                <div>{{ details.subtotal }}</div>
              </td>
              <td style="text-align: center">
                <div>{{ details.industryType | industryType }}</div>
              </td>
            </tr>
          </table>
          <div class="yue">月度履约数据</div>
          <table style="border: 1px solid gray">
            <tr>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                合计
              </th>
              <th
                style="
                  width: 70px;
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                进度履约率(%)
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                1月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                2月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                3月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                4月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                5月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                6月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                7月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                8月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                9月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                10月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                11月
              </th>
              <th style="border-bottom: 1px solid gray">12月</th>
            </tr>
            <tr>
              <td
                style="border-right: 1px solid gray; text-align: center；width:100%;text-align: center;"
              >
                {{
                  Number(performanceSubmitted.januay) +
                    Number(performanceSubmitted.autonomyJanuay) +
                    Number(performanceSubmitted.february) +
                    Number(performanceSubmitted.autonomyFebruary) +
                    Number(performanceSubmitted.autonomyMarch) +
                    Number(performanceSubmitted.march) +
                    Number(performanceSubmitted.autonomyApril) +
                    Number(performanceSubmitted.april) +
                    Number(performanceSubmitted.autonomyMay) +
                    Number(performanceSubmitted.may) +
                    Number(performanceSubmitted.autonomyJune) +
                    Number(performanceSubmitted.june) +
                    Number(performanceSubmitted.autonomyJuly) +
                    Number(performanceSubmitted.july) +
                    Number(performanceSubmitted.autonomyAugust) +
                    Number(performanceSubmitted.august) +
                    Number(performanceSubmitted.autonomySeptember) +
                    Number(performanceSubmitted.september) +
                    Number(performanceSubmitted.autonomyOctober) +
                    Number(performanceSubmitted.october) +
                    Number(performanceSubmitted.autonomyNovember) +
                    Number(performanceSubmitted.november) +
                    Number(performanceSubmitted.autonomyDecember) +
                    Number(performanceSubmitted.december) || ""
                }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{ implementSubmitted.scheduleNumber }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{
                  floatCompute.floatAdd(
                    Number(performanceSubmitted.januay) +
                      Number(performanceSubmitted.autonomyJanuay)
                  ) || ""
                }}
              </td>
              <td style="border-right: 1px solid gray; text-align: center">
                {{
                  floatCompute.floatAdd(
                    Number(performanceSubmitted.february) +
                      Number(performanceSubmitted.autonomyFebruary)
                  ) || ""
                }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{
                  floatCompute.floatAdd(
                    Number(performanceSubmitted.autonomyMarch) +
                      Number(performanceSubmitted.march)
                  ) || ""
                }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{
                  floatCompute.floatAdd(
                    Number(performanceSubmitted.autonomyApril) +
                      Number(performanceSubmitted.april)
                  ) || ""
                }}
              </td>
              <td style="border-right: 1px solid gray; text-align: center">
                {{
                  floatCompute.floatAdd(
                    Number(performanceSubmitted.autonomyMay) +
                      Number(performanceSubmitted.may)
                  ) || ""
                }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{
                  floatCompute.floatAdd(
                    Number(performanceSubmitted.autonomyJune) +
                      Number(performanceSubmitted.june)
                  ) || ""
                }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{
                  floatCompute.floatAdd(
                    Number(performanceSubmitted.autonomyJuly) +
                      Number(performanceSubmitted.july)
                  ) || ""
                }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{
                  floatCompute.floatAdd(
                    Number(performanceSubmitted.autonomyAugust) +
                      Number(performanceSubmitted.august)
                  ) || ""
                }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{
                  floatCompute.floatAdd(
                    Number(performanceSubmitted.autonomySeptember) +
                      Number(performanceSubmitted.september)
                  ) || ""
                }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{
                  floatCompute.floatAdd(
                    Number(performanceSubmitted.autonomyOctober) +
                      Number(performanceSubmitted.october)
                  ) || ""
                }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{
                  floatCompute.floatAdd(
                    Number(performanceSubmitted.autonomyNovember) +
                      Number(performanceSubmitted.november)
                  ) || ""
                }}
              </td>
              <td style="text-align: center">
                {{
                  floatCompute.floatAdd(
                    Number(performanceSubmitted.autonomyDecember) +
                      Number(performanceSubmitted.december)
                  ) || ""
                }}
              </td>
            </tr>
            <tr style="border-top: 1px solid gray">
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                  text-align: center;
                "
                colspan="2"
              >
                基础价+浮动价执行数量
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  type="text"
                  v-model="performanceSubmitted.januay"
                  @blur="inputBlur('januay')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                  @input="performanceSubmittedChange('januay')"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  type="text"
                  v-model="performanceSubmitted.february"
                  @blur="inputBlur('february')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                  @input="performanceSubmittedChange('february')"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  type="text"
                  v-model="performanceSubmitted.march"
                  @blur="inputBlur('march')"
                  @input="performanceSubmittedChange('march')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  type="text"
                  @input="performanceSubmittedChange('april')"
                  v-model="performanceSubmitted.april"
                  @blur="inputBlur('april')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  type="text"
                  @input="performanceSubmittedChange('may')"
                  v-model="performanceSubmitted.may"
                  @blur="inputBlur('may')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  type="text"
                  @input="performanceSubmittedChange('june')"
                  v-model="performanceSubmitted.june"
                  @blur="inputBlur('june')"
                  maxlength="10"
                  :disabled="istrue"
                  style="text-align: center"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  type="text"
                  v-model="performanceSubmitted.july"
                  @input="performanceSubmittedChange('july')"
                  @blur="inputBlur('july')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="performanceSubmittedChange('august')"
                  type="text"
                  v-model="performanceSubmitted.august"
                  @blur="inputBlur('august')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="performanceSubmittedChange('september')"
                  type="text"
                  v-model="performanceSubmitted.september"
                  @blur="inputBlur('september')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="performanceSubmittedChange('october')"
                  type="text"
                  v-model="performanceSubmitted.october"
                  @blur="inputBlur('october')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="performanceSubmittedChange('november')"
                  type="text"
                  v-model="performanceSubmitted.november"
                  @blur="inputBlur('november')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td style="border-bottom: 1px solid gray">
                <el-input
                  @input="performanceSubmittedChange('december')"
                  type="text"
                  v-model="performanceSubmitted.december"
                  @blur="inputBlur('december')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
            </tr>
            <tr style="border-top: 1px solid gray">
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                  text-align: center;
                "
                colspan="2"
              >
                自主协商执行数量
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="performanceSubmittedChange('autonomyJanuay')"
                  type="text"
                  v-model="performanceSubmitted.autonomyJanuay"
                  @blur="inputBlur('autonomyJanuay')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="performanceSubmittedChange('autonomyFebruary')"
                  type="text"
                  v-model="performanceSubmitted.autonomyFebruary"
                  @blur="inputBlur('autonomyFebruary')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="performanceSubmittedChange('autonomyMarch')"
                  type="text"
                  v-model="performanceSubmitted.autonomyMarch"
                  @blur="inputBlur('autonomyMarch')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="performanceSubmittedChange('autonomyApril')"
                  type="text"
                  v-model="performanceSubmitted.autonomyApril"
                  @blur="inputBlur('autonomyApril')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="performanceSubmittedChange('autonomyMay')"
                  type="text"
                  v-model="performanceSubmitted.autonomyMay"
                  @blur="inputBlur('autonomyMay')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="performanceSubmittedChange('autonomyJune')"
                  type="text"
                  v-model="performanceSubmitted.autonomyJune"
                  @blur="inputBlur('autonomyJune')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="performanceSubmittedChange('autonomyJuly')"
                  type="text"
                  v-model="performanceSubmitted.autonomyJuly"
                  @blur="inputBlur('autonomyJuly')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="performanceSubmittedChange('autonomyAugust')"
                  type="text"
                  v-model="performanceSubmitted.autonomyAugust"
                  @blur="inputBlur('autonomyAugust')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="performanceSubmittedChange('autonomySeptember')"
                  type="text"
                  v-model="performanceSubmitted.autonomySeptember"
                  @blur="inputBlur('autonomySeptember')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="performanceSubmittedChange('autonomyOctober')"
                  type="text"
                  v-model="performanceSubmitted.autonomyOctober"
                  @blur="inputBlur('autonomyOctober')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="performanceSubmittedChange('autonomyNovember')"
                  type="text"
                  v-model="performanceSubmitted.autonomyNovember"
                  @blur="inputBlur('autonomyNovember')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
              <td style="border-bottom: 1px solid gray">
                <el-input
                  @input="performanceSubmittedChange('autonomyDecember')"
                  type="text"
                  v-model="performanceSubmitted.autonomyDecember"
                  @blur="inputBlur('autonomyDecember')"
                  maxlength="10"
                  style="text-align: center"
                  :disabled="istrue"
                />
              </td>
            </tr>
            <tr style="border-top: 1px solid gray">
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                  text-align: center;
                "
                colspan="2"
              >
                季度履约率(%)
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                  text-align: center;
                "
                colspan="3"
              >
                {{ implementSubmitted.oneScheduleNumber }}
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                  text-align: center;
                "
                colspan="3"
              >
                {{ implementSubmitted.twoScheduleNumber }}
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                  text-align: center;
                "
                colspan="3"
              >
                {{ implementSubmitted.threeScheduleNumber }}
              </td>
              <td
                style="border-bottom: 1px solid gray; text-align: center"
                colspan="3"
              >
                {{ implementSubmitted.fourScheduleNumber }}
              </td>
            </tr>

            <tr style="border-top: 1px solid gray">
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                  text-align: center;
                "
                colspan="2"
              >
                未达标原因
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                  text-align: center;
                "
                colspan="3"
              >
                <!-- <input
                  type="text"
                  style="width: 100%"
                  v-model="performanceSubmitted.firstQuarter"
                /> -->
                <el-button
                  @click="AddfirstQuarter('1')"
                  :disabled="
                    !performanceSubmitted.januay &&
                    !performanceSubmitted.february &&
                    !performanceSubmitted.march &&
                    !performanceSubmitted.autonomyJanuay &&
                    !performanceSubmitted.autonomyFebruary &&
                    !performanceSubmitted.autonomyMarch
                  "
                  >填写未达标原因</el-button
                >
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                  text-align: center;
                "
                colspan="3"
              >
                <!-- <input
                  type="text"
                  style="width: 100%"
                  v-model="performanceSubmitted.secondQuarter"
                /> -->
                <el-button
                  @click="AddfirstQuarter('2')"
                  :disabled="
                    !performanceSubmitted.april &&
                    !performanceSubmitted.may &&
                    !performanceSubmitted.june &&
                    !performanceSubmitted.autonomyApril &&
                    !performanceSubmitted.autonomyMay &&
                    !performanceSubmitted.autonomyJune
                  "
                  >填写未达标原因</el-button
                >
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                  text-align: center;
                "
                colspan="3"
              >
                <!-- <input
                  type="text"
                  style="width: 100%"
                  v-model="performanceSubmitted.thirdQuarter"
                /> -->
                <el-button
                  @click="AddfirstQuarter('3')"
                  :disabled="
                    !performanceSubmitted.july &&
                    !performanceSubmitted.august &&
                    !performanceSubmitted.september &&
                    !performanceSubmitted.autonomyJuly &&
                    !performanceSubmitted.autonomyAugust &&
                    !performanceSubmitted.autonomySeptember
                  "
                  >填写未达标原因</el-button
                >
              </td>
              <td
                style="border-bottom: 1px solid gray; text-align: center"
                colspan="3"
              >
                <!-- <input
                  type="text"
                  style="width: 100%"
                  v-model="performanceSubmitted.fourthQuarter"
                /> -->
                <el-button
                  @click="AddfirstQuarter('4')"
                  :disabled="
                    !performanceSubmitted.october &&
                    !performanceSubmitted.november &&
                    !performanceSubmitted.december &&
                    !performanceSubmitted.autonomyOctober &&
                    !performanceSubmitted.autonomyNovember &&
                    !performanceSubmitted.autonomyDecember
                  "
                  >填写未达标原因</el-button
                >
              </td>
            </tr>
          </table>

          <div class="yue">月度执行价格</div>
          <table style="border: 1px solid gray">
            <tr>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                累计平均价格
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                1月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                2月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                3月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                4月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                5月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                6月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                7月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                8月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                9月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                10月
              </th>
              <th
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                11月
              </th>
              <th style="border-bottom: 1px solid gray">12月</th>
            </tr>
            <tr>
              <td style="border-right: 1px solid gray; text-align: center">
                {{ yearpic }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{ implementSubmitted.januay1 }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{ implementSubmitted.february1 }}
              </td>
              <td style="border-right: 1px solid gray; text-align: center">
                {{ implementSubmitted.march1 }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{ implementSubmitted.april1 }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{ implementSubmitted.may1 }}
              </td>
              <td style="border-right: 1px solid gray; text-align: center">
                {{ implementSubmitted.june1 }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{ implementSubmitted.july1 }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{ implementSubmitted.august1 }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{ implementSubmitted.september1 }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{ implementSubmitted.october1 }}
              </td>
              <td style="text-align: center; border-right: 1px solid gray">
                {{ implementSubmitted.november1 }}
              </td>
              <td style="text-align: center">
                {{ implementSubmitted.december1 }}
              </td>
            </tr>
            <tr style="border-top: 1px solid gray">
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                  text-align: center;
                "
              >
                基础价+浮动价执行价格
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('januay')"
                  type="text"
                  v-model="implementSubmitted.januay"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.januay &&
                      !performanceSubmitted.autonomyJanuay)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('february')"
                  type="text"
                  v-model="implementSubmitted.february"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.february &&
                      !performanceSubmitted.autonomyFebruary)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('march')"
                  type="text"
                  v-model="implementSubmitted.march"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.march &&
                      !performanceSubmitted.autonomyMarch)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('april')"
                  type="text"
                  v-model="implementSubmitted.april"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.april &&
                      !performanceSubmitted.autonomyApril)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('may')"
                  type="text"
                  v-model="implementSubmitted.may"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.may &&
                      !performanceSubmitted.autonomyMay)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('june')"
                  type="text"
                  v-model="implementSubmitted.june"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.june &&
                      !performanceSubmitted.autonomyJune)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('july')"
                  type="text"
                  v-model="implementSubmitted.july"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.july &&
                      !performanceSubmitted.autonomyJuly)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('august')"
                  type="text"
                  v-model="implementSubmitted.august"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.august &&
                      !performanceSubmitted.autonomyAugust)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('september')"
                  type="text"
                  v-model="implementSubmitted.september"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.august &&
                      !performanceSubmitted.autonomySeptember)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('october')"
                  type="text"
                  v-model="implementSubmitted.october"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.YearBlur &&
                      !performanceSubmitted.autonomyOctober)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('november')"
                  type="text"
                  v-model="implementSubmitted.november"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.november &&
                      !performanceSubmitted.autonomyNovember)
                  "
                />
              </td>
              <td style="border-bottom: 1px solid gray">
                <el-input
                  @input="implementSubmittedChange('december')"
                  type="text"
                  v-model="implementSubmitted.december"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.december &&
                      !performanceSubmitted.autonomyDecember)
                  "
                />
              </td>
            </tr>
            <tr style="border-top: 1px solid gray">
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                  text-align: center;
                "
              >
                自主协商执行价格
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('autonomyJanuay')"
                  type="text"
                  v-model="implementSubmitted.autonomyJanuay"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.januay &&
                      !performanceSubmitted.autonomyJanuay)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('autonomyFebruary')"
                  type="text"
                  v-model="implementSubmitted.autonomyFebruary"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.february &&
                      !performanceSubmitted.autonomyFebruary)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('autonomyMarch')"
                  type="text"
                  v-model="implementSubmitted.autonomyMarch"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.march &&
                      !performanceSubmitted.autonomyMarch)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('autonomyApril')"
                  type="text"
                  v-model="implementSubmitted.autonomyApril"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.april &&
                      !performanceSubmitted.autonomyApril)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('autonomyMay')"
                  type="text"
                  v-model="implementSubmitted.autonomyMay"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.may &&
                      !performanceSubmitted.autonomyMay)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('autonomyJune')"
                  type="text"
                  v-model="implementSubmitted.autonomyJune"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.june &&
                      !performanceSubmitted.autonomyJune)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('autonomyJuly')"
                  type="text"
                  v-model="implementSubmitted.autonomyJuly"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.july &&
                      !performanceSubmitted.autonomyJuly)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('autonomyAugust')"
                  type="text"
                  v-model="implementSubmitted.autonomyAugust"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.august &&
                      !performanceSubmitted.autonomyAugust)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('autonomySeptember')"
                  type="text"
                  v-model="implementSubmitted.autonomySeptember"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.august &&
                      !performanceSubmitted.autonomySeptember)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('autonomyOctober')"
                  type="text"
                  v-model="implementSubmitted.autonomyOctober"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.YearBlur &&
                      !performanceSubmitted.autonomyOctober)
                  "
                />
              </td>
              <td
                style="
                  border-bottom: 1px solid gray;
                  border-right: 1px solid gray;
                "
              >
                <el-input
                  @input="implementSubmittedChange('autonomyNovember')"
                  type="text"
                  v-model="implementSubmitted.autonomyNovember"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.november &&
                      !performanceSubmitted.autonomyNovember)
                  "
                />
              </td>
              <td style="border-bottom: 1px solid gray">
                <el-input
                  @input="implementSubmittedChange('autonomyDecember')"
                  type="text"
                  v-model="implementSubmitted.autonomyDecember"
                  @blur="YearBlur"
                  :disabled="
                    istrue ||
                    (!performanceSubmitted.december &&
                      !performanceSubmitted.autonomyDecember)
                  "
                />
              </td>
            </tr>
          </table>

          <div style="color: red; margin-top: 10px">
            <div>
              注：1.报送履约数据口径为结算数据，当月未履约的合同，履约数据请填“0”；
            </div>
            <div>2.修改数据可直接在已填报数据处修改；</div>
            <div>
              3.月度履约数据为“基准价+浮动价”执行数量
              +“自主协商”执行数量；月度执行价格为两种执行价格加权平均；
            </div>
            <div>
              4.进度履约率计算公式：(1月数据+...+N月数据)/(1月合同数据+...+N月合同数据)
              ，其中有名录的合同且名录量与合同量不一致时，以名录量为基础计算；
            </div>
            <div>
              5.季度履约率计算公式(1季度为例)：(1月数据+...+3月数据)/(1月合同数据+...+3月合同数据)；
            </div>
            <div>
              6.累计平均价格计算公式：(1月价格+...+N月价格)/(价格数量)。
            </div>
          </div>
          <div class="btn">
            <el-button type="primary" plain @click="PushClick">报送</el-button>
            <el-button type="warning" plain @click="returnClick"
              >返回</el-button
            >
          </div>
          <!-- <div class="btn">
            <el-button type="warning" plain @click="returnClick"
              >关闭</el-button
            >
          </div> -->
        </div>

        <el-dialog title="未达标说明" :visible.sync="dialogVisible1">
          <div>
            <el-form
              ref="NoStandardForm1"
              :model="NoStandardForm1"
              label-width="80px"
            >
              <el-form-item label="附件上传">
                <div style="display: flex">
                  <el-input
                    style="width: 200px"
                    v-model="NoStandardForm1.fileName"
                    disabled
                  ></el-input>
                  <el-upload
                    style="margin-left: 10px"
                    class="upload-demo"
                    action="#"
                    multiple
                    :limit="1"
                    :before-upload="handleImageBeforeUpload"
                  >
                    <el-button class="up_btn" size="small" type="primary"
                      >上传</el-button
                    >
                  </el-upload>
                </div>
              </el-form-item>
              <el-form-item label="其他原因">
                <el-input
                  type="textarea"
                  :rows="2"
                  v-model="NoStandardForm1.otherReason"
                ></el-input>
              </el-form-item>
              <el-form-item label="原因类型">
                <el-select
                  @change="optionChange1"
                  v-model="NoStandardForm1.value1"
                  placeholder="请选择供方原因"
                >
                  <el-option
                    v-for="item in options1"
                    :key="item.dictId"
                    :label="item.dictLabel"
                    :value="item.dictId"
                  >
                  </el-option>
                </el-select>
                <el-select
                  @change="optionChange2"
                  v-model="NoStandardForm1.value2"
                  placeholder="请选择需方原因"
                  style="margin-left: 10px"
                >
                  <el-option
                    v-for="item in options2"
                    :key="item.dictId"
                    :label="item.dictLabel"
                    :value="item.dictId"
                  >
                  </el-option>
                </el-select>
                <el-select
                  @change="optionChange3"
                  v-model="NoStandardForm1.value3"
                  placeholder="请选择铁路原因"
                  style="margin-left: 10px"
                >
                  <el-option
                    v-for="item in options3"
                    :key="item.dictId"
                    :label="item.dictLabel"
                    :value="item.dictId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible1 = false">取 消</el-button>
            <el-button type="primary" @click="adddialogVisible1"
              >确 定</el-button
            >
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getSubmitted,
  addSubmitted,
  getSysDictDataByByDictType,
} from "@/api/Medium.js";
import floatCompute from "@/utils/floatCompute.js";
import { ossUploadFiletwo } from "@/api/public.js";
export default {
  data() {
    return {
      autonomyList: {},
      floatCompute: floatCompute,
      options1: [],
      options2: [],
      options3: [],
      dialogVisible1: false,
      NoStandardForm1: {
        fileName: "",
        fileId: "",
        fileName: "",
        otherReason: "",
        value1: "",
        value2: "",
        value3: "",
      },
      // 月度执行价格
      implementSubmitted: {
        januay: "",
        february: "",
        march: "",
        april: "",
        may: "",
        june: "",
        july: "",
        august: "",
        september: "",
        october: "",
        november: "",
        december: "",
        autonomyJanuay: "",
        autonomyFebruary: "",
        autonomyMarch: "",
        autonomyApril: "",
        autonomyMay: "",
        autonomyJune: "",
        autonomyJuly: "",
        autonomyAugust: "",
        autonomySeptember: "",
        autonomyOctober: "",
        autonomyNovember: "",
        autonomyDecember: "",
        scheduleNumber: "",
        oneScheduleNumber: "",
        twoScheduleNumber: "",
        threeScheduleNumber: "",
        fourScheduleNumber: "",
      },
      // 月度履约数据
      performanceSubmitted: {
        januay: "",
        february: "",
        march: "",
        april: "",
        may: "",
        june: "",
        july: "",
        august: "",
        september: "",
        october: "",
        november: "",
        december: "",
        autonomyJanuay: "",
        autonomyFebruary: "",
        autonomyMarch: "",
        autonomyApril: "",
        autonomyMay: "",
        autonomyJune: "",
        autonomyJuly: "",
        autonomyAugust: "",
        autonomySeptember: "",
        autonomyOctober: "",
        autonomyNovember: "",
        autonomyDecember: "",
        firstQuarter: "",
        secondQuarter: "",
        thirdQuarter: "",
        fourthQuarter: "",
        reasons: [],
      },
      details: {
        no: "",
        sellerName: "",
        buyerName: "",
        departure: "",
        arrive: "",
        typeOfShipping: "",
        subtotal: "",
        industryType: "",
      },

      list: [],
      newlist: [],
      laList: [],
      //进度履约率
      yearpic: 0,
      reasonVal: null,
      istrue: false,
    };
  },
  components: {
    Left: () => import("../../components/left1.vue"),
  },
  mounted() {
    let type = this.$route.query.type;
    if (type) {
      this.istrue = true;
    } else {
      this.istrue = false;
    }
    this.getdetile();
    this.getoptionlist();
  },
  filters: {
    typeOfShipping(val) {
      let _data = {
        1: "铁水联运（国铁）",
        2: "铁路直达（国铁）",
        3: "自有铁路",
        4: "公路运输",
        5: "其他运输",
      };
      return _data[val];
    },
    industryType(val) {
      switch (val) {
        case "1":
          return "电力";
          break;
        case "2":
          return "冶金";
          break;
        case "3":
          return "建材";
          break;
        case "4":
          return "化工";
          break;
        case "5":
          return "其他";
          break;
      }
    },
  },
  methods: {
    performanceSubmittedChange(name) {
      if (this.performanceSubmitted[name] == "") {
        this.implementSubmitted[name] = "";
      }
      this.performanceSubmitted[name] = this.performanceSubmitted[name].replace(
        /^\D*([0-9]\d*\.?\d{0,2})?.*$/,
        "$1"
      );
    },
    toDecimal(x) {
      var f = parseFloat(x);
      if (isNaN(f)) {
        return;
      }
      f = Math.round(x * 100) / 100;
      return f;
    },
    implementSubmittedChange(name) {
      if (this.implementSubmitted[name] == "") {
        this.implementSubmitted[name] = "";
      }

      if (name == "januay" || name == "autonomyJanuay") {
        if (this.implementSubmitted[name] == null) {
          this.implementSubmitted[name] = 0;
        }
        if (this.performanceSubmitted[name] == null) {
          this.performanceSubmitted[name] = 0;
        }
        let a =
          Number(this.implementSubmitted.januay) *
          Number(this.performanceSubmitted.januay);
        let b =
          Number(this.implementSubmitted.autonomyJanuay) *
          Number(this.performanceSubmitted.autonomyJanuay);
        let c = a + b;
        let d =
          Number(this.performanceSubmitted.autonomyJanuay) +
          Number(this.performanceSubmitted.januay);
        let f = floatCompute.floatDiv(c, d);
        this.implementSubmitted.januay1 = this.toDecimal(f);
      }
      if (name == "february" || name == "autonomyFebruary") {
        if (this.implementSubmitted[name] == null) {
          this.implementSubmitted[name] = 0;
        }
        if (this.performanceSubmitted[name] == null) {
          this.performanceSubmitted[name] = 0;
        }
        let a =
          Number(this.implementSubmitted.february) *
          Number(this.performanceSubmitted.february);
        let b =
          Number(this.implementSubmitted.autonomyFebruary) *
          Number(this.performanceSubmitted.autonomyFebruary);
        let c = a + b;
        let d =
          Number(this.performanceSubmitted.autonomyFebruary) +
          Number(this.performanceSubmitted.february);
        let f = floatCompute.floatDiv(c, d);
        this.implementSubmitted.february1 = this.toDecimal(f);
      }
      if (name == "march" || name == "autonomyMarch") {
        if (this.implementSubmitted[name] == null) {
          this.implementSubmitted[name] = 0;
        }
        if (this.performanceSubmitted[name] == null) {
          this.performanceSubmitted[name] = 0;
        }
        let a =
          Number(this.implementSubmitted.march) *
          Number(this.performanceSubmitted.march);
        let b =
          Number(this.implementSubmitted.autonomyMarch) *
          Number(this.performanceSubmitted.autonomyMarch);
        let c = a + b;
        let d =
          Number(this.performanceSubmitted.autonomyMarch) +
          Number(this.performanceSubmitted.march);
        let f = floatCompute.floatDiv(c, d);
        this.implementSubmitted.march1 = this.toDecimal(f);
      }
      if (name == "april" || name == "autonomyApril") {
        if (this.implementSubmitted[name] == null) {
          this.implementSubmitted[name] = 0;
        }
        if (this.performanceSubmitted[name] == null) {
          this.performanceSubmitted[name] = 0;
        }
        let a =
          Number(this.implementSubmitted.april) *
          Number(this.performanceSubmitted.april);
        let b =
          Number(this.implementSubmitted.autonomyApril) *
          Number(this.performanceSubmitted.autonomyApril);
        let c = a + b;
        let d =
          Number(this.performanceSubmitted.autonomyApril) +
          Number(this.performanceSubmitted.april);
        let f = floatCompute.floatDiv(c, d);
        this.implementSubmitted.april1 = this.toDecimal(f);
      }
      if (name == "may" || name == "autonomyMay") {
        if (this.implementSubmitted[name] == null) {
          this.implementSubmitted[name] = 0;
        }
        if (this.performanceSubmitted[name] == null) {
          this.performanceSubmitted[name] = 0;
        }
        let a =
          Number(this.implementSubmitted.may) *
          Number(this.performanceSubmitted.may);
        let b =
          Number(this.implementSubmitted.autonomyMay) *
          Number(this.performanceSubmitted.autonomyMay);
        let c = a + b;
        let d =
          Number(this.performanceSubmitted.autonomyMay) +
          Number(this.performanceSubmitted.may);
        let f = floatCompute.floatDiv(c, d);
        this.implementSubmitted.may1 = this.toDecimal(f);
      }
      if (name == "june" || name == "autonomyJune") {
        if (this.implementSubmitted[name] == null) {
          this.implementSubmitted[name] = 0;
        }
        if (this.performanceSubmitted[name] == null) {
          this.performanceSubmitted[name] = 0;
        }
        let a =
          Number(this.implementSubmitted.june) *
          Number(this.performanceSubmitted.june);
        let b =
          Number(this.implementSubmitted.autonomyJune) *
          Number(this.performanceSubmitted.autonomyJune);
        let c = a + b;
        let d =
          Number(this.performanceSubmitted.autonomyJune) +
          Number(this.performanceSubmitted.june);
        let f = floatCompute.floatDiv(c, d);
        this.implementSubmitted.june1 = this.toDecimal(f);
      }
      if (name == "july" || name == "autonomyJuly") {
        if (this.implementSubmitted[name] == null) {
          this.implementSubmitted[name] = 0;
        }
        if (this.performanceSubmitted[name] == null) {
          this.performanceSubmitted[name] = 0;
        }
        let a =
          Number(this.implementSubmitted.july) *
          Number(this.performanceSubmitted.july);
        let b =
          Number(this.implementSubmitted.autonomyJuly) *
          Number(this.performanceSubmitted.autonomyJuly);
        let c = a + b;
        let d =
          Number(this.performanceSubmitted.autonomyJuly) +
          Number(this.performanceSubmitted.july);
        let f = floatCompute.floatDiv(c, d);
        this.implementSubmitted.july1 = this.toDecimal(f);
      }
      if (name == "august" || name == "autonomyAugust") {
        if (this.implementSubmitted[name] == null) {
          this.implementSubmitted[name] = 0;
        }
        if (this.performanceSubmitted[name] == null) {
          this.performanceSubmitted[name] = 0;
        }
        let a =
          Number(this.implementSubmitted.august) *
          Number(this.performanceSubmitted.august);
        let b =
          Number(this.implementSubmitted.autonomyAugust) *
          Number(this.performanceSubmitted.autonomyAugust);
        let c = a + b;
        let d =
          Number(this.performanceSubmitted.autonomyAugust) +
          Number(this.performanceSubmitted.august);
        let f = floatCompute.floatDiv(c, d);
        this.implementSubmitted.august1 = this.toDecimal(f);
      }
      if (name == "september" || name == "autonomySeptember") {
        if (this.implementSubmitted[name] == null) {
          this.implementSubmitted[name] = 0;
        }
        if (this.performanceSubmitted[name] == null) {
          this.performanceSubmitted[name] = 0;
        }
        let a =
          Number(this.implementSubmitted.september) *
          Number(this.performanceSubmitted.september);
        let b =
          Number(this.implementSubmitted.autonomySeptember) *
          Number(this.performanceSubmitted.autonomySeptember);
        let c = a + b;
        let d =
          Number(this.performanceSubmitted.autonomySeptember) +
          Number(this.performanceSubmitted.september);
        let f = floatCompute.floatDiv(c, d);
        this.implementSubmitted.september1 = this.toDecimal(f);
      }
      if (name == "october" || name == "autonomyOctober") {
        if (this.implementSubmitted[name] == null) {
          this.implementSubmitted[name] = 0;
        }
        if (this.performanceSubmitted[name] == null) {
          this.performanceSubmitted[name] = 0;
        }
        let a =
          Number(this.implementSubmitted.october) *
          Number(this.performanceSubmitted.october);
        let b =
          Number(this.implementSubmitted.autonomyOctober) *
          Number(this.performanceSubmitted.autonomyOctober);
        let c = a + b;
        let d =
          Number(this.performanceSubmitted.autonomyOctober) +
          Number(this.performanceSubmitted.october);
        let f = floatCompute.floatDiv(c, d);
        this.implementSubmitted.october1 = this.toDecimal(f);
      }

      if (name == "november" || name == "autonomyNovember") {
        if (this.implementSubmitted[name] == null) {
          this.implementSubmitted[name] = 0;
        }
        if (this.performanceSubmitted[name] == null) {
          this.performanceSubmitted[name] = 0;
        }
        let a =
          Number(this.implementSubmitted.november) *
          Number(this.performanceSubmitted.november);
        let b =
          Number(this.implementSubmitted.autonomyNovember) *
          Number(this.performanceSubmitted.autonomyNovember);
        let c = a + b;
        let d =
          Number(this.performanceSubmitted.autonomyNovember) +
          Number(this.performanceSubmitted.november);
        let f = floatCompute.floatDiv(c, d);
        this.implementSubmitted.november1 = this.toDecimal(f);
      }

      if (name == "december" || name == "autonomyDecember") {
        if (this.implementSubmitted[name] == null) {
          this.implementSubmitted[name] = 0;
        }
        if (this.performanceSubmitted[name] == null) {
          this.performanceSubmitted[name] = 0;
        }
        let a =
          Number(this.implementSubmitted.december) *
          Number(this.performanceSubmitted.december);
        let b =
          Number(this.implementSubmitted.autonomyDecember) *
          Number(this.performanceSubmitted.autonomyDecember);
        let c = a + b;
        let d =
          Number(this.performanceSubmitted.autonomyDecember) +
          Number(this.performanceSubmitted.december);
        let f = floatCompute.floatDiv(c, d);
        this.implementSubmitted.december1 = this.toDecimal(f);
      }
      // this.implementSubmitted[a]=(Number(this.implementSubmitted[name]) *
      //     Number(this.performanceSubmitted[name]) +
      //     Number(this.implementSubmitted[name]) *
      //       Number(this.performanceSubmitted[name])) /
      //     (Number(this.performanceSubmitted[name]) +
      //       Number(this.performanceSubmitted[name]))
      // this.implementSubmitted[a]=this.implementSubmitted[a].replace(
      //         /^\D*([0-9]\d*\.?\d{0,2})?.*$/,
      //         "$1"
      //       );
      if (
        this.implementSubmitted[name] &&
        this.implementSubmitted[name] != ""
      ) {
        this.implementSubmitted[name] = this.implementSubmitted[name].replace(
          /^\D*([0-9]\d*\.?\d{0,2})?.*$/,
          "$1"
        );
      }
    },
    adddialogVisible1() {
      for (let i = 0; i < this.laList.length; i++) {
        if (this.laList[i].type == this.reasonVal) {
          this.laList.splice(i, 1);
        }
        if (this.laList[i]) {
          if (Array.isArray(this.laList[i].reason)) {
            this.laList[i].reason = this.laList[i].reason.join();
          }
        }
      }
      console.log(this.laList);
      let obj = {};
      obj.fileId = this.NoStandardForm1.fileId;
      obj.fileName = this.NoStandardForm1.fileName;
      obj.otherReason = this.NoStandardForm1.otherReason;
      obj.reason = [];
      obj.reason.push(this.NoStandardForm1.value1);
      obj.reason.push(this.NoStandardForm1.value2);
      obj.reason.push(this.NoStandardForm1.value3);
      obj.reason = obj.reason.join();
      obj.type = this.reasonVal;
      this.laList.push(obj);
      this.performanceSubmitted.reasons = this.laList;
      this.dialogVisible1 = false;
    },
    optionChange1(e) {
      console.log(e);
      this.NoStandardForm1.value1 = e;
    },
    optionChange2(e) {
      this.NoStandardForm1.value2 = e;
    },
    optionChange3(e) {
      this.NoStandardForm1.value3 = e;
    },
    getoptionlist() {
      let config1 = {
        dictType: "submitted_reason_supplier",
        pageSize: 0,
        pageNum: 0,
        dictLabel: "",
      };
      let config2 = {
        dictType: "submitted_reason_demander",
        pageSize: 0,
        pageNum: 0,
        dictLabel: "",
      };
      let config3 = {
        dictType: "submitted_reason_railway",
        pageSize: 0,
        pageNum: 0,
        dictLabel: "",
      };
      getSysDictDataByByDictType(config1).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.options1 = res.data.list;
        }
      });
      getSysDictDataByByDictType(config2).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.options2 = res.data.list;
        }
      });
      getSysDictDataByByDictType(config3).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.options3 = res.data.list;
        }
      });
    },
    handleImageBeforeUpload(file) {
      // let xls = file.name.slice(-3);
      // let pdf = file.name.slice(-4);
      // let isJPG;
      // if (pdf === "docx") {
      //   isJPG = true;
      // } else {
      //   isJPG = false;
      // }
      let otherFiles = file;
      // FormData 对象
      var formData = new FormData();
      // 文件对象
      // let name = file.name.split(".")[0];
      formData.append("file", otherFiles);
      // if (isJPG) {
      ossUploadFiletwo(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.NoStandardForm1.fileId = res.data.fileId;
          this.NoStandardForm1.fileName = res.data.originalName;
        }
      });
      // }else{
      //   this.$message.error('请上传格式为：docx的合同文件');
      // }
    },
    AddfirstQuarter(val) {
      this.dialogVisible1 = true;
      this.reasonVal = val;
      let list = this.laList;
      console.log(list);
      if (list.length > 0) {
        for (let i = 0; i < list.length; i++) {
          console.log(list[i].type, val, "----------------");
          if (list[i].type == val) {
            console.log("11111111111111111111111", val);
            if (!Array.isArray(list[i].reason)) {
              list[i].reason = list[i].reason.split(",");
            }
            this.NoStandardForm1 = {
              fileId: list[i].fileId,
              fileName: list[i].fileName,
              otherReason: list[i].otherReason,
              value1: list[i].reason[0],
              value2: list[i].reason[1],
              value3: list[i].reason[2],
              type: val,
            };
            return;
          } else {
            console.log("22222222222222222222222", val);
            this.NoStandardForm1 = {
              fileId: "",
              fileName: "",
              otherReason: "",
              value1: "",
              value2: "",
              value3: "",
              type: val,
            };
          }
        }
      } else {
        this.NoStandardForm1 = {
          fileId: "",
          fileName: "",
          otherReason: "",
          value1: "",
          value2: "",
          value3: "",
          type: val,
        };
      }
      // this.NoStandardForm1 = {
      //   fileId: "",
      //   fileName: "",
      //   otherReason: "",
      //   value1: "",
      //   value2: "",
      //   value3: "",
      // };

      // let id = this.$route.query.id;
      // if (id) {
      //   this.reasonVal = this.performanceSubmitted.reasons;
      //   console.log(this.reasonVal, "------------");
      //   for (let i = 0; i < this.reasonVal.length; i++) {
      //     if (!Array.isArray(this.reasonVal[i].reason)) {
      //       this.reasonVal[i].reason = this.reasonVal[i].reason.split(",");
      //     }
      //     if (this.reasonVal[i].type == val) {
      //       this.NoStandardForm1 = {
      //         fileId: this.reasonVal[i].fileId,
      //         fileName: this.reasonVal[i].fileName,
      //         otherReason: this.reasonVal[i].otherReason,
      //         value1: this.reasonVal[i].reason[0],
      //         value2: this.reasonVal[i].reason[1],
      //         value3: this.reasonVal[i].reason[2],
      //         type: val,
      //       };
      //     } else {
      // this.NoStandardForm1 = {
      //   fileId: "",
      //   fileName: "",
      //   otherReason: "",
      //   value1: "",
      //   value2: "",
      //   value3: "",
      //   type: val,
      // };
      //     }
      //   }
      //   console.log(this.reasonVal.length )

      // if (this.reasonVal.length  == val||this.reasonVal.length >val) {
      //   let i = val - 1;
      //   if (this.reasonVal[i].type == val) {
      //     this.NoStandardForm1 = {
      //       fileId: this.reasonVal[i].fileId,
      //       fileName: this.reasonVal[i].fileName,
      //       otherReason: this.reasonVal[i].otherReason,
      //       value1: this.reasonVal[i].reason[0],
      //       value2: this.reasonVal[i].reason[1],
      //       value3: this.reasonVal[i].reason[2],
      //       type: val,
      //     };
      //   }
      // } else {
      //   this.NoStandardForm1 = {
      //     fileId: "",
      //     fileName: "",
      //     otherReason: "",
      //     value1: "",
      //     value2: "",
      //     value3: "",
      //     type: val,
      //   };

      //   console.log("滴滴滴滴滴");
      // }
      // if (
      //   this.performanceSubmitted.reasons &&
      //   this.performanceSubmitted.reasons.length > 0
      // ) {
      //   let row = this.performanceSubmitted.reasons[val - 1];
      //   console.log(row);

      // }
      // }

      // this.reasonVal = val;
      // console.log(this.reasonVal);
    },
    getdetile() {
      let id = this.$route.query.id;
      getSubmitted(id).then((res) => {
        this.details = res.data;
        if (res.data.performanceSubmitted) {
          this.performanceSubmitted = res.data.performanceSubmitted;
          if (
            res.data.performanceSubmitted.reasons &&
            res.data.performanceSubmitted.reasons.length > 0
          ) {
            this.laList = res.data.performanceSubmitted.reasons;
            for (let i = 0; i < this.laList.length; i++) {
              if (this.laList[i]) {
                if (Array.isArray(this.laList[i].reason)) {
                  this.laList[i].reason = this.laList[i].reason.join();
                }
              }
            }
          }
          // if (res.data.performanceSubmitted.reasons) {
          //   this.laList = res.data.performanceSubmitted.reasons;
          // }
          // if (
          //   res.data.performanceSubmitted.reasons &&
          //   res.data.performanceSubmitted.reasons.length > 0
          // ) {
          //   this.performanceSubmitted.reasons =
          //     res.data.performanceSubmitted.reasons;
          // }
          // let conname = "";
          // if (
          //   res.data.performanceSubmitted.autonomyJanuay ||
          //   res.data.performanceSubmitted.januay
          // ) {
          //   conname = "januay";
          //   this.autonomyList[conname] =
          //     res.data.contractOtherInfoDTOS[0][conname];
          // }
          // if (
          //   res.data.performanceSubmitted.autonomyFebruary ||
          //   res.data.performanceSubmitted.february
          // ) {
          //   conname = "february";
          //   this.autonomyList[conname] =
          //     res.data.contractOtherInfoDTOS[0][conname];
          // }
          // if (
          //   res.data.performanceSubmitted.autonomyMarch ||
          //   res.data.performanceSubmitted.march
          // ) {
          //   conname = "march";
          //   this.autonomyList[conname] =
          //     res.data.contractOtherInfoDTOS[0][conname];
          // }
          // if (
          //   res.data.performanceSubmitted.autonomyApril ||
          //   res.data.performanceSubmitted.april
          // ) {
          //   conname = "april";
          //   this.autonomyList[conname] =
          //     res.data.contractOtherInfoDTOS[0][conname];
          // }
          // if (
          //   res.data.performanceSubmitted.autonomyMay ||
          //   res.data.performanceSubmitted.may
          // ) {
          //   conname = "may";
          //   this.autonomyList[conname] =
          //     res.data.contractOtherInfoDTOS[0][conname];
          // }
          // if (
          //   res.data.performanceSubmitted.autonomyJune ||
          //   res.data.performanceSubmitted.june
          // ) {
          //   conname = "june";
          //   this.autonomyList[conname] =
          //     res.data.contractOtherInfoDTOS[0][conname];
          // }
          // if (
          //   res.data.performanceSubmitted.autonomyJuly ||
          //   res.data.performanceSubmitted.july
          // ) {
          //   conname = "july";
          //   this.autonomyList[conname] =
          //     res.data.contractOtherInfoDTOS[0][conname];
          // }
          // if (
          //   res.data.performanceSubmitted.autonomyAugust ||
          //   res.data.performanceSubmitted.august
          // ) {
          //   conname = "august";
          //   this.autonomyList[conname] =
          //     res.data.contractOtherInfoDTOS[0][conname];
          // }
          // if (
          //   res.data.performanceSubmitted.autonomySeptember ||
          //   res.data.performanceSubmitted.september
          // ) {
          //   conname = "september";
          //   this.autonomyList[conname] =
          //     res.data.contractOtherInfoDTOS[0][conname];
          // }

          // if (
          //   res.data.performanceSubmitted.autonomyOctober ||
          //   res.data.performanceSubmitted.october
          // ) {
          //   conname = "october";
          //   this.autonomyList[conname] =
          //     res.data.contractOtherInfoDTOS[0][conname];
          // }

          // if (
          //   res.data.performanceSubmitted.autonomyNovember ||
          //   res.data.performanceSubmitted.november
          // ) {
          //   conname = "november";
          //   this.autonomyList[conname] =
          //     res.data.contractOtherInfoDTOS[0][conname];
          // }

          // if (
          //   res.data.performanceSubmitted.autonomyDecember ||
          //   res.data.performanceSubmitted.december
          // ) {
          //   conname = "december";
          //   this.autonomyList[conname] =
          //     res.data.contractOtherInfoDTOS[0][conname];
          // }
          // console.log(this.autonomyList);
          // let sum = 0;
          // this.autonomyList[conname] =
          //   this.details.contractOtherInfoDTOS[0][conname];
          // console.log(this.autonomyList);
          // let hlist = [];
          // for (let key in this.autonomyList) {
          //   if (this.autonomyList[key]) {
          //     hlist.push(this.autonomyList[key]);
          //   }
          // }

          // for (let i = 0; i < hlist.length; i++) {
          //   sum += Number(hlist[i]);
          // }
          // let a =
          //   Number(this.performanceSubmitted.januay) +
          //   Number(this.performanceSubmitted.autonomyJanuay) +
          //   Number(this.performanceSubmitted.february) +
          //   Number(this.performanceSubmitted.autonomyFebruary) +
          //   Number(this.performanceSubmitted.autonomyMarch) +
          //   Number(this.performanceSubmitted.march) +
          //   Number(this.performanceSubmitted.autonomyApril) +
          //   Number(this.performanceSubmitted.april) +
          //   Number(this.performanceSubmitted.autonomyMay) +
          //   Number(this.performanceSubmitted.may) +
          //   Number(this.performanceSubmitted.autonomyJune) +
          //   Number(this.performanceSubmitted.june) +
          //   Number(this.performanceSubmitted.autonomyJuly) +
          //   Number(this.performanceSubmitted.july) +
          //   Number(this.performanceSubmitted.autonomyAugust) +
          //   Number(this.performanceSubmitted.august) +
          //   Number(this.performanceSubmitted.autonomySeptember) +
          //   Number(this.performanceSubmitted.september) +
          //   Number(this.performanceSubmitted.autonomyOctober) +
          //   Number(this.performanceSubmitted.october) +
          //   Number(this.performanceSubmitted.autonomyNovember) +
          //   Number(this.performanceSubmitted.november) +
          //   Number(this.performanceSubmitted.autonomyDecember) +
          //   Number(this.performanceSubmitted.december);
          // if (a == 0) {
          //   this.implementSubmitted.scheduleNumber = 0;
          // } else {
          //   this.implementSubmitted.scheduleNumber = ((a / sum) * 100).toFixed(
          //     2
          //   );
          // }
        }
        if (res.data.implementSubmitted) {
          this.implementSubmitted = res.data.implementSubmitted;
          if (
            this.implementSubmitted.januay ||
            this.implementSubmitted.autonomyJanuay
          ) {
            let a =
              Number(this.implementSubmitted.januay) *
              Number(this.performanceSubmitted.januay);
            let b =
              Number(this.implementSubmitted.autonomyJanuay) *
              Number(this.performanceSubmitted.autonomyJanuay);
            let c = a + b;
            let d =
              Number(this.performanceSubmitted.autonomyJanuay) +
              Number(this.performanceSubmitted.januay);
            let f = floatCompute.floatDiv(c, d);
            this.implementSubmitted.januay1 = this.toDecimal(f);
          }
          if (
            this.implementSubmitted.february ||
            this.implementSubmitted.autonomyFebruary
          ) {
            let a =
              Number(this.implementSubmitted.february) *
              Number(this.performanceSubmitted.february);
            let b =
              Number(this.implementSubmitted.autonomyFebruary) *
              Number(this.performanceSubmitted.autonomyFebruary);
            let c = a + b;
            let d =
              Number(this.performanceSubmitted.autonomyFebruary) +
              Number(this.performanceSubmitted.february);
            let f = floatCompute.floatDiv(c, d);
            this.implementSubmitted.february1 = this.toDecimal(f);
          }
          if (
            this.implementSubmitted.march ||
            this.implementSubmitted.autonomyMarch
          ) {
            let a =
              Number(this.implementSubmitted.march) *
              Number(this.performanceSubmitted.march);
            let b =
              Number(this.implementSubmitted.autonomyMarch) *
              Number(this.performanceSubmitted.autonomyMarch);
            let c = a + b;
            let d =
              Number(this.performanceSubmitted.autonomyMarch) +
              Number(this.performanceSubmitted.march);
            let f = floatCompute.floatDiv(c, d);
            this.implementSubmitted.march1 = this.toDecimal(f);
          }
          if (
            this.implementSubmitted.april ||
            this.implementSubmitted.autonomyApril
          ) {
            let a =
              Number(this.implementSubmitted.april) *
              Number(this.performanceSubmitted.april);
            let b =
              Number(this.implementSubmitted.autonomyApril) *
              Number(this.performanceSubmitted.autonomyApril);
            let c = a + b;
            let d =
              Number(this.performanceSubmitted.autonomyApril) +
              Number(this.performanceSubmitted.april);
            let f = floatCompute.floatDiv(c, d);
            this.implementSubmitted.april1 = this.toDecimal(f);
          }
          if (
            this.implementSubmitted.may ||
            this.implementSubmitted.autonomyMay
          ) {
            let a =
              Number(this.implementSubmitted.may) *
              Number(this.performanceSubmitted.may);
            let b =
              Number(this.implementSubmitted.autonomyMay) *
              Number(this.performanceSubmitted.autonomyMay);
            let c = a + b;
            let d =
              Number(this.performanceSubmitted.autonomyMay) +
              Number(this.performanceSubmitted.may);
            let f = floatCompute.floatDiv(c, d);
            this.implementSubmitted.may1 = this.toDecimal(f);
          }
          if (
            this.implementSubmitted.june ||
            this.implementSubmitted.autonomyJune
          ) {
            let a =
              Number(this.implementSubmitted.june) *
              Number(this.performanceSubmitted.june);
            let b =
              Number(this.implementSubmitted.autonomyJune) *
              Number(this.performanceSubmitted.autonomyJune);
            let c = a + b;
            let d =
              Number(this.performanceSubmitted.autonomyJune) +
              Number(this.performanceSubmitted.june);
            let f = floatCompute.floatDiv(c, d);
            this.implementSubmitted.june1 = this.toDecimal(f);
          }
          if (
            this.implementSubmitted.july ||
            this.implementSubmitted.autonomyJuly
          ) {
            let a =
              Number(this.implementSubmitted.july) *
              Number(this.performanceSubmitted.july);
            let b =
              Number(this.implementSubmitted.autonomyJuly) *
              Number(this.performanceSubmitted.autonomyJuly);
            let c = a + b;
            let d =
              Number(this.performanceSubmitted.autonomyJuly) +
              Number(this.performanceSubmitted.july);
            let f = floatCompute.floatDiv(c, d);
            this.implementSubmitted.july1 = this.toDecimal(f);
          }
          if (
            this.implementSubmitted.august ||
            this.implementSubmitted.autonomyAugust
          ) {
            let a =
              Number(this.implementSubmitted.august) *
              Number(this.performanceSubmitted.august);
            let b =
              Number(this.implementSubmitted.autonomyAugust) *
              Number(this.performanceSubmitted.autonomyAugust);
            let c = a + b;
            let d =
              Number(this.performanceSubmitted.autonomyAugust) +
              Number(this.performanceSubmitted.august);
            let f = floatCompute.floatDiv(c, d);
            this.implementSubmitted.august1 = this.toDecimal(f);
          }
          if (
            this.implementSubmitted.september ||
            this.implementSubmitted.autonomySeptember
          ) {
            let a =
              Number(this.implementSubmitted.september) *
              Number(this.performanceSubmitted.september);
            let b =
              Number(this.implementSubmitted.autonomySeptember) *
              Number(this.performanceSubmitted.autonomySeptember);
            let c = a + b;
            let d =
              Number(this.performanceSubmitted.autonomySeptember) +
              Number(this.performanceSubmitted.september);
            let f = floatCompute.floatDiv(c, d);
            this.implementSubmitted.september1 = this.toDecimal(f);
          }
          if (
            this.implementSubmitted.october ||
            this.implementSubmitted.autonomyOctober
          ) {
            let a =
              Number(this.implementSubmitted.october) *
              Number(this.performanceSubmitted.october);
            let b =
              Number(this.implementSubmitted.autonomyOctober) *
              Number(this.performanceSubmitted.autonomyOctober);
            let c = a + b;
            let d =
              Number(this.performanceSubmitted.autonomyOctober) +
              Number(this.performanceSubmitted.october);
            let f = floatCompute.floatDiv(c, d);
            this.implementSubmitted.october1 = this.toDecimal(f);
          }

          if (
            this.implementSubmitted.november ||
            this.implementSubmitted.autonomyNovember
          ) {
            let a =
              Number(this.implementSubmitted.november) *
              Number(this.performanceSubmitted.november);
            let b =
              Number(this.implementSubmitted.autonomyNovember) *
              Number(this.performanceSubmitted.autonomyNovember);
            let c = a + b;
            let d =
              Number(this.performanceSubmitted.autonomyNovember) +
              Number(this.performanceSubmitted.november);
            let f = floatCompute.floatDiv(c, d);
            this.implementSubmitted.november1 = this.toDecimal(f);
          }

          if (
            this.implementSubmitted.december ||
            this.implementSubmitted.autonomyDecember
          ) {
            let a =
              Number(this.implementSubmitted.december) *
              Number(this.performanceSubmitted.december);
            let b =
              Number(this.implementSubmitted.autonomyDecember) *
              Number(this.performanceSubmitted.autonomyDecember);
            let c = a + b;
            let d =
              Number(this.performanceSubmitted.autonomyDecember) +
              Number(this.performanceSubmitted.december);
            let f = floatCompute.floatDiv(c, d);
            this.implementSubmitted.december1 = this.toDecimal(f);
          }
        }
        this.YearBlur();
      });
    },
    YearBlur() {
      let list = [];
      console.log(this.implementSubmitted);
      if (this.implementSubmitted.januay1) {
        list.push(this.implementSubmitted.januay1);
      }
      if (this.implementSubmitted.february1) {
        list.push(this.implementSubmitted.february1);
      }
      if (this.implementSubmitted.march1) {
        list.push(this.implementSubmitted.march1);
      }
      if (this.implementSubmitted.april1) {
        list.push(this.implementSubmitted.april1);
      }
      if (this.implementSubmitted.may1) {
        list.push(this.implementSubmitted.may1);
      }
      if (this.implementSubmitted.june1) {
        list.push(this.implementSubmitted.june1);
      }
      if (this.implementSubmitted.july1) {
        list.push(this.implementSubmitted.july1);
      }
      if (this.implementSubmitted.august1) {
        list.push(this.implementSubmitted.august1);
      }
      if (this.implementSubmitted.september1) {
        list.push(this.implementSubmitted.september1);
      }
      if (this.implementSubmitted.october1) {
        list.push(this.implementSubmitted.october1);
      }
      if (this.implementSubmitted.november1) {
        list.push(this.implementSubmitted.november1);
      }
      if (this.implementSubmitted.december1) {
        list.push(this.implementSubmitted.december1);
      }
      let sum = 0;
      for (let i = 0; i < list.length; i++) {
        sum += Number(list[i]);
      }
      if (list.length > 0) {
        this.yearpic = floatCompute.floatDiv(sum, list.length).toFixed(2);
      } else {
        this.yearpic = 0;
      }
      console.log(sum, list.length);
      // this.yearpic = (
      //   (Number(this.implementSubmitted.januay) +
      //     Number(this.implementSubmitted.autonomyJanuay) +
      //     Number(this.implementSubmitted.february) +
      //     Number(this.implementSubmitted.autonomyFebruary) +
      //     Number(this.implementSubmitted.march) +
      //     Number(this.implementSubmitted.autonomyMarch) +
      //     Number(this.implementSubmitted.april) +
      //     Number(this.implementSubmitted.autonomyApril) +
      //     Number(this.implementSubmitted.may) +
      //     Number(this.implementSubmitted.autonomyMay) +
      //     Number(this.implementSubmitted.june) +
      //     Number(this.implementSubmitted.autonomyJune) +
      //     Number(this.implementSubmitted.july) +
      //     Number(this.implementSubmitted.autonomyJuly) +
      //     Number(this.implementSubmitted.august) +
      //     Number(this.implementSubmitted.autonomyAugust) +
      //     Number(this.implementSubmitted.september) +
      //     Number(this.implementSubmitted.autonomySeptember) +
      //     Number(this.implementSubmitted.october) +
      //     Number(this.implementSubmitted.autonomyOctober) +
      //     Number(this.implementSubmitted.november) +
      //     Number(this.implementSubmitted.autonomyNovember) +
      //     Number(this.implementSubmitted.december) +
      //     Number(this.implementSubmitted.autonomyDecember)) /
      //   12
      // ).toFixed(2);
    },
    onblurSum(val) {
      let sum = 0;
      let conname = "";
      if (
        (val == "autonomyJanuay" && this.performanceSubmitted[val]) ||
        (val == "januay" && this.performanceSubmitted[val])
      ) {
        conname = "januay";
      }
      if (
        (val == "autonomyFebruary" && this.performanceSubmitted[val]) ||
        (val == "february" && this.performanceSubmitted[val])
      ) {
        conname = "february";
      }
      if (
        (val == "autonomyMarch" && this.performanceSubmitted[val]) ||
        (val == "march" && this.performanceSubmitted[val])
      ) {
        conname = "march";
      }
      if (
        (val == "autonomyApril" && this.performanceSubmitted[val]) ||
        (val == "april" && this.performanceSubmitted[val])
      ) {
        conname = "april";
      }
      if (
        (val == "autonomyMay" && this.performanceSubmitted[val]) ||
        (val == "may" && this.performanceSubmitted[val])
      ) {
        conname = "may";
      }
      if (
        (val == "autonomyJune" && this.performanceSubmitted[val]) ||
        (val == "june" && this.performanceSubmitted[val])
      ) {
        conname = "june";
      }
      if (
        (val == "autonomyJuly" && this.performanceSubmitted[val]) ||
        (val == "july" && this.performanceSubmitted[val])
      ) {
        conname = "july";
      }
      if (
        (val == "autonomyAugust" && this.performanceSubmitted[val]) ||
        (val == "august" && this.performanceSubmitted[val])
      ) {
        conname = "august";
      }
      if (
        (val == "autonomySeptember" && this.performanceSubmitted[val]) ||
        (val == "september" && this.performanceSubmitted[val])
      ) {
        conname = "september";
      }
      if (
        (val == "autonomyOctober" && this.performanceSubmitted[val]) ||
        (val == "october" && this.performanceSubmitted[val])
      ) {
        conname = "october";
      }
      if (
        (val == "autonomyNovember" && this.performanceSubmitted[val]) ||
        (val == "november" && this.performanceSubmitted[val])
      ) {
        conname = "november";
      }
      if (
        (val == "autonomyDecember" && this.performanceSubmitted[val]) ||
        (val == "december" && this.performanceSubmitted[val])
      ) {
        conname = "december";
      }

      if (conname != "") {
        this.autonomyList[conname] =
          this.details.contractOtherInfoDTOS[0][conname];
      } else {
        Reflect.deleteProperty(this.autonomyList, conname);
      }

      if (
        this.performanceSubmitted[val] == "" ||
        this.performanceSubmitted[val] < 1
      ) {
        Reflect.deleteProperty(this.autonomyList, val);
      }
      // console.log(this.autonomyList, "------------");
      let hlist = [];
      for (let key in this.autonomyList) {
        if (this.autonomyList[key]) {
          hlist.push(this.autonomyList[key]);
        }
      }
      for (let i = 0; i < hlist.length; i++) {
        sum += Number(hlist[i]);
      }

      let a =
        Number(this.performanceSubmitted.januay) +
        Number(this.performanceSubmitted.autonomyJanuay) +
        Number(this.performanceSubmitted.february) +
        Number(this.performanceSubmitted.autonomyFebruary) +
        Number(this.performanceSubmitted.autonomyMarch) +
        Number(this.performanceSubmitted.march) +
        Number(this.performanceSubmitted.autonomyApril) +
        Number(this.performanceSubmitted.april) +
        Number(this.performanceSubmitted.autonomyMay) +
        Number(this.performanceSubmitted.may) +
        Number(this.performanceSubmitted.autonomyJune) +
        Number(this.performanceSubmitted.june) +
        Number(this.performanceSubmitted.autonomyJuly) +
        Number(this.performanceSubmitted.july) +
        Number(this.performanceSubmitted.autonomyAugust) +
        Number(this.performanceSubmitted.august) +
        Number(this.performanceSubmitted.autonomySeptember) +
        Number(this.performanceSubmitted.september) +
        Number(this.performanceSubmitted.autonomyOctober) +
        Number(this.performanceSubmitted.october) +
        Number(this.performanceSubmitted.autonomyNovember) +
        Number(this.performanceSubmitted.november) +
        Number(this.performanceSubmitted.autonomyDecember) +
        Number(this.performanceSubmitted.december);
      if (sum == 0) {
        this.implementSubmitted.scheduleNumber = 0;
      } else {
        this.implementSubmitted.scheduleNumber = ((a / sum) * 100).toFixed(2);
      }
    },
    inputBlur(val) {
      this.implementSubmittedChange(val);
      console.log(this.performanceSubmitted[val], "-----------");
      let newobj = this.performanceSubmitted;
      for (const key in newobj) {
        this.onblurSum(key);
      }
      if (
        Number(this.performanceSubmitted.januay) +
          Number(this.performanceSubmitted.autonomyJanuay) !=
          0 ||
        Number(this.performanceSubmitted.february) +
          Number(this.performanceSubmitted.autonomyFebruary) !=
          0 ||
        Number(this.performanceSubmitted.autonomyMarch) +
          Number(this.performanceSubmitted.march) !=
          0
      ) {
        let list = [];
        if (this.performanceSubmitted.januay) {
          list.push(this.performanceSubmitted.januay);
        }
        if (this.performanceSubmitted.autonomyJanuay) {
          list.push(this.performanceSubmitted.autonomyJanuay);
        }
        if (this.performanceSubmitted.february) {
          list.push(this.performanceSubmitted.february);
        }
        if (this.performanceSubmitted.autonomyFebruary) {
          list.push(this.performanceSubmitted.autonomyFebruary);
        }
        if (this.performanceSubmitted.autonomyMarch) {
          list.push(this.performanceSubmitted.autonomyMarch);
        }
        if (this.performanceSubmitted.march) {
          list.push(this.performanceSubmitted.march);
        }
        let sum = 0;
        for (let i = 0; i < list.length; i++) {
          sum += Number(list[i]);
        }
        let newlist = [];
        if (
          this.performanceSubmitted.januay ||
          this.performanceSubmitted.autonomyJanuay
        ) {
          newlist.push(this.details.contractOtherInfoDTOS[0]["januay"]);
        }
        if (
          this.performanceSubmitted.february ||
          this.performanceSubmitted.autonomyFebruary
        ) {
          newlist.push(this.details.contractOtherInfoDTOS[0]["february"]);
        }
        if (
          this.performanceSubmitted.autonomyMarch ||
          this.performanceSubmitted.march
        ) {
          newlist.push(this.details.contractOtherInfoDTOS[0]["march"]);
        }
        let newsum = 0;
        for (let i = 0; i < newlist.length; i++) {
          newsum += Number(newlist[i]);
        }
        let e = (sum / newsum) * 100;
        this.implementSubmitted.oneScheduleNumber = e.toFixed(2);
      } else {
        this.implementSubmitted.oneScheduleNumber = 0;
      }

      if (
        Number(this.performanceSubmitted.autonomyApril) +
          Number(this.performanceSubmitted.april) !=
          0 ||
        Number(this.performanceSubmitted.autonomyMay) +
          Number(this.performanceSubmitted.may) !=
          0 ||
        Number(this.performanceSubmitted.autonomyJune) +
          Number(this.performanceSubmitted.june) !=
          0
      ) {
        let list = [];
        if (this.performanceSubmitted.autonomyApril) {
          list.push(this.performanceSubmitted.autonomyApril);
        }
        if (this.performanceSubmitted.april) {
          list.push(this.performanceSubmitted.april);
        }
        if (this.performanceSubmitted.may) {
          list.push(this.performanceSubmitted.may);
        }
        if (this.performanceSubmitted.autonomyMay) {
          list.push(this.performanceSubmitted.autonomyMay);
        }
        if (this.performanceSubmitted.autonomyJune) {
          list.push(this.performanceSubmitted.autonomyJune);
        }
        if (this.performanceSubmitted.june) {
          list.push(this.performanceSubmitted.june);
        }
        let sum = 0;
        for (let i = 0; i < list.length; i++) {
          sum += Number(list[i]);
        }
        let newlist = [];
        if (
          this.performanceSubmitted.autonomyApril ||
          this.performanceSubmitted.april
        ) {
          newlist.push(this.details.contractOtherInfoDTOS[0]["april"]);
        }
        if (
          this.performanceSubmitted.may ||
          this.performanceSubmitted.autonomyMay
        ) {
          newlist.push(this.details.contractOtherInfoDTOS[0]["may"]);
        }
        if (
          this.performanceSubmitted.autonomyJune ||
          this.performanceSubmitted.june
        ) {
          newlist.push(this.details.contractOtherInfoDTOS[0]["june"]);
        }
        let newsum = 0;
        for (let i = 0; i < newlist.length; i++) {
          newsum += Number(newlist[i]);
        }
        // let a =
        //   Number(this.performanceSubmitted.autonomyApril) +
        //   Number(this.performanceSubmitted.april) +
        //   Number(this.performanceSubmitted.autonomyMay) +
        //   Number(this.performanceSubmitted.may) +
        //   Number(this.performanceSubmitted.autonomyJune) +
        //   Number(this.performanceSubmitted.june);
        // let b =
        //   Number(this.details.contractOtherInfoDTOS[0]["april"]) +
        //   Number(this.details.contractOtherInfoDTOS[0]["may"]) +

        //   Number(this.details.contractOtherInfoDTOS[0]["june"]);
        let e = (sum / newsum) * 100;
        this.implementSubmitted.twoScheduleNumber = e.toFixed(2);
      } else {
        this.implementSubmitted.twoScheduleNumber = 0;
      }

      if (
        Number(this.performanceSubmitted.autonomyJuly) +
          Number(this.performanceSubmitted.july) !=
          0 ||
        Number(this.performanceSubmitted.autonomyAugust) +
          Number(this.performanceSubmitted.august) !=
          0 ||
        Number(this.performanceSubmitted.autonomySeptember) +
          Number(this.performanceSubmitted.september) !=
          0
      ) {
        let list = [];
        if (this.performanceSubmitted.autonomyJuly) {
          list.push(this.performanceSubmitted.autonomyJuly);
        }
        if (this.performanceSubmitted.july) {
          list.push(this.performanceSubmitted.july);
        }
        if (this.performanceSubmitted.august) {
          list.push(this.performanceSubmitted.august);
        }
        if (this.performanceSubmitted.autonomyAugust) {
          list.push(this.performanceSubmitted.autonomyAugust);
        }
        if (this.performanceSubmitted.autonomySeptember) {
          list.push(this.performanceSubmitted.autonomySeptember);
        }
        if (this.performanceSubmitted.september) {
          list.push(this.performanceSubmitted.september);
        }
        let sum = 0;
        for (let i = 0; i < list.length; i++) {
          sum += Number(list[i]);
        }
        let newlist = [];
        if (
          this.performanceSubmitted.autonomyJuly ||
          this.performanceSubmitted.july
        ) {
          newlist.push(this.details.contractOtherInfoDTOS[0]["july"]);
        }
        if (
          this.performanceSubmitted.august ||
          this.performanceSubmitted.autonomyAugust
        ) {
          newlist.push(this.details.contractOtherInfoDTOS[0]["august"]);
        }
        if (
          this.performanceSubmitted.autonomySeptember ||
          this.performanceSubmitted.september
        ) {
          newlist.push(this.details.contractOtherInfoDTOS[0]["september"]);
        }
        let newsum = 0;
        for (let i = 0; i < newlist.length; i++) {
          newsum += Number(newlist[i]);
        }

        // let a =
        //   Number(this.performanceSubmitted.autonomyJuly) +
        //   Number(this.performanceSubmitted.july) +
        //   Number(this.performanceSubmitted.autonomyAugust) +
        //   Number(this.performanceSubmitted.august) +
        //   Number(this.performanceSubmitted.autonomySeptember) +
        //   Number(this.performanceSubmitted.september);
        // let b =
        //   Number(this.details.contractOtherInfoDTOS[0]["july"]) +
        //   Number(this.details.contractOtherInfoDTOS[0]["august"]) +
        //   Number(this.details.contractOtherInfoDTOS[0]["september"]);
        let e = (sum / newsum) * 100;
        this.implementSubmitted.threeScheduleNumber = e.toFixed(2);
      } else {
        this.implementSubmitted.threeScheduleNumber = 0;
      }

      if (
        Number(this.performanceSubmitted.autonomyOctober) +
          Number(this.performanceSubmitted.october) !=
          0 ||
        Number(this.performanceSubmitted.autonomyNovember) +
          Number(this.performanceSubmitted.november) !=
          0 ||
        Number(this.performanceSubmitted.autonomyDecember) +
          Number(this.performanceSubmitted.december) !=
          0
      ) {
        let list = [];
        if (this.performanceSubmitted.autonomyOctober) {
          list.push(this.performanceSubmitted.autonomyOctober);
        }
        if (this.performanceSubmitted.october) {
          list.push(this.performanceSubmitted.october);
        }
        if (this.performanceSubmitted.november) {
          list.push(this.performanceSubmitted.november);
        }
        if (this.performanceSubmitted.autonomyNovember) {
          list.push(this.performanceSubmitted.autonomyNovember);
        }
        if (this.performanceSubmitted.autonomyDecember) {
          list.push(this.performanceSubmitted.autonomyDecember);
        }
        if (this.performanceSubmitted.december) {
          list.push(this.performanceSubmitted.december);
        }
        let sum = 0;
        for (let i = 0; i < list.length; i++) {
          sum += Number(list[i]);
        }
        let newlist = [];
        if (
          this.performanceSubmitted.autonomyOctober ||
          this.performanceSubmitted.october
        ) {
          newlist.push(this.details.contractOtherInfoDTOS[0]["october"]);
        }
        if (
          this.performanceSubmitted.november ||
          this.performanceSubmitted.autonomyNovember
        ) {
          newlist.push(this.details.contractOtherInfoDTOS[0]["november"]);
        }
        if (
          this.performanceSubmitted.autonomyDecember ||
          this.performanceSubmitted.december
        ) {
          newlist.push(this.details.contractOtherInfoDTOS[0]["december"]);
        }
        let newsum = 0;
        for (let i = 0; i < newlist.length; i++) {
          newsum += Number(newlist[i]);
        }

        // let a =
        //   Number(this.performanceSubmitted.autonomyOctober) +
        //   Number(this.performanceSubmitted.october) +
        //   Number(this.performanceSubmitted.autonomyNovember) +
        //   Number(this.performanceSubmitted.november) +
        //   Number(this.performanceSubmitted.autonomyDecember) +
        //   Number(this.performanceSubmitted.december);
        // let b =
        //   Number(this.details.contractOtherInfoDTOS[0]["october"]) +
        //   Number(this.details.contractOtherInfoDTOS[0]["november"]) +
        //   Number(this.details.contractOtherInfoDTOS[0]["december"]);
        let e = (sum / newsum) * 100;
        this.implementSubmitted.fourScheduleNumber = e.toFixed(2);
      } else {
        this.implementSubmitted.fourScheduleNumber = 0;
      }
      this.YearBlur();
      // console.log(this.list,this.newlist)
      // console.log(this.details.performanceSubmitted[val])
    },
    PushClick() {
      this.$confirm("确认提交此条报送数据吗??", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (
            this.performanceSubmitted.reasons &&
            this.performanceSubmitted.reasons.length > 0
          ) {
            for (let i = 0; i < this.performanceSubmitted.reasons.length; i++) {
              if (this.performanceSubmitted.reasons[i]) {
                if (
                  Array.isArray(this.performanceSubmitted.reasons[i].reason)
                ) {
                  this.performanceSubmitted.reasons[i].reason =
                    this.performanceSubmitted.reasons[i].reason.join();
                }
              }
            }
          }
          let config = {
            contractId: this.$route.query.id,
            implementSubmitted: this.implementSubmitted,
            performanceSubmitted: this.performanceSubmitted,
          };
          config.implementSubmitted.totalNumber =
            Number(this.performanceSubmitted.januay) +
            Number(this.performanceSubmitted.autonomyJanuay) +
            Number(this.performanceSubmitted.february) +
            Number(this.performanceSubmitted.autonomyFebruary) +
            Number(this.performanceSubmitted.february) +
            Number(this.performanceSubmitted.autonomyFebruary) +
            Number(this.performanceSubmitted.autonomyMarch) +
            Number(this.performanceSubmitted.march) +
            Number(this.performanceSubmitted.autonomyApril) +
            Number(this.performanceSubmitted.april) +
            Number(this.performanceSubmitted.autonomyMay) +
            Number(this.performanceSubmitted.may) +
            Number(this.performanceSubmitted.autonomyJune) +
            Number(this.performanceSubmitted.june) +
            Number(this.performanceSubmitted.autonomyJuly) +
            Number(this.performanceSubmitted.july) +
            Number(this.performanceSubmitted.autonomyAugust) +
            Number(this.performanceSubmitted.august) +
            Number(this.performanceSubmitted.autonomySeptember) +
            Number(this.performanceSubmitted.september) +
            Number(this.performanceSubmitted.autonomyOctober) +
            Number(this.performanceSubmitted.october) +
            Number(this.performanceSubmitted.autonomyNovember) +
            Number(this.performanceSubmitted.november) +
            Number(this.performanceSubmitted.autonomyDecember) +
            Number(this.performanceSubmitted.december);
          addSubmitted(config).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "推送成功",
                type: "success",
              });
              this.$router.go(-1);
            } else {
              this.$message({
                message: res.message,
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    returnClick() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body .el-input .el-input__inner {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  width: 100%;
}
::v-deep .el-dialog__body .el-select .el-input--suffix {
  input {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    width: 100%;
    padding: 0 24px;
  }
}
::v-deep .el-input.is-disabled .el-input__inner {
  background: none;
  border-color: none;
  color: none;
}
::v-deep .el-input__inner {
  text-align: center;
  padding: 0;
  width: 50px;
  border: none;
}
.submitted {
  width: 1196px;
  margin: 0 auto;
  display: flex;
  .right {
    width: 970px;
    .conter {
      width: 100%;
      border: 1px solid #ccc;
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
  }
}
.Line {
  width: 100%;
  height: 1px;
  background: #ccc;
}
.title {
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
}
.right_cont {
  text-align: right;
  margin-top: 5px;
  color: #ccc;
}
table {
  th {
    height: 40px;
  }
  td {
    height: 40px;
  }
  // margin-top: 10px;
  max-width: 941px;
  input {
    max-width: 65px;
  }
}
.yue {
  max-width: 941px;
  border-right: 1px solid black;
  border-left: 1px solid black;
  text-align: center;
  height: 40px;
  line-height: 40px;
  // margin-top: 10px;
}
.btn {
  text-align: center;
  margin-top: 20px;
}
</style>