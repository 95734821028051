<template>
  <div class="opencicc">
    <stop1 v-if="active == 0" @logoutHeader="logoutHeader"></stop1>
    <stop2 v-if="active == 1" @logoutHeader2="logoutHeader2"></stop2>
    <stop3 v-if="active == 2" @logoutHeader3="logoutHeader3"></stop3>
    <stop4 v-if="active == 3" @logoutHeader4="logoutHeader4"></stop4>
  </div>
</template>

<script>
import * as types from "@/store/action-types";
import { getAccountStatusForZJ } from "@/api/Accountopening";
import Vue from "vue";
export default {
  data() {
    return {
      active: 0,
    };
  },
  mounted() {
    Vue.ls.set(types.active, 0);
    this.active = Vue.ls.get(types.active);
    this.getAccountStatus();
  },
  methods: {
    ActiveClick(e){
      console.log(e)
    },
    getAccountStatus() {
      getAccountStatusForZJ().then((res) => {
          if (res.data.signStatus == 30) {
            this.$router.push({
              path: "/manage",
            });
          }
      });
    },

    logoutHeader(e) {
      this.active = e;
    },
    logoutHeader2(e) {
      this.active = e;
    },
    logoutHeader3(e) {
      this.active = e;
    },
    logoutHeader4(e) {
      this.active = e;
    },
  },
  components: {
    stop1: () => import("./components/stop1.vue"),
    stop2: () => import("./components/stop2.vue"),
    stop3: () => import("./components/stop3.vue"),
    stop4: () => import("./components/stop4.vue"),
  },
};
</script>

<style lang="scss" scoped>
.opencicc {
  background: #fff;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
</style>