<template>
  <div class="addtemplate">
    <div class="conter">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="220px"
        class="demo-ruleForm"
      >
        <el-form-item label="模板名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <div class="title">合同条款</div>
        <el-form-item label="一：交(提)货方式" prop="deliveryMethod">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="详情见补充协议"
            v-model="ruleForm.deliveryMethod"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="二：质量和数量验收标准及方法"
          prop="qualityQuantity"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="详情见补充协议"
            v-model="ruleForm.qualityQuantity"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="三：煤炭单价及执行期" prop="price">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="详情见补充协议"
            v-model="ruleForm.price"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="四：货款,运杂费结算方式及期限" prop="loan">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="详情见补充协议"
            v-model="ruleForm.loan"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="五：违约责任" prop="liability">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="详情见补充协议"
            v-model="ruleForm.liability"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="六：解决合同纠纷的方式" prop="contractDispute">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="详情见补充协议"
            v-model="ruleForm.contractDispute"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="七：其他约定事项" prop="other">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="详情见补充协议"
            v-model="ruleForm.other"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button type="primary" @click="onSubmit('ruleForm')"
              >保存</el-button
            >
            <el-button @click="closeClick">关闭</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  addAndEditContractTemplate,
  getContractTemplateInfo,
} from "@/api/Medium.js";
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        deliveryMethod: "",
        qualityQuantity: "",
        price: "",
        loan: "",
        liability: "",
        contractDispute: "",
        other: "",
        id: "",
      },
      rules: {
        name: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
      },
    };
  },
  mounted() {
    console.log(this.$route.query.id);
    if (this.$route.query.id) {
      this.getContractTemplateInfo();
    }
  },
  methods: {
    getContractTemplateInfo() {
      getContractTemplateInfo(this.$route.query.id).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.ruleForm = res.data;
        }
      });
    },
    //保存
    onSubmit(formName) {
      if (this.$route.query.id) {
        this.ruleForm.id = this.$route.query.id;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addAndEditContractTemplate(this.ruleForm).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.$router.go(-1);
            }
          });
        }
      });
    },
    closeClick() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.addtemplate {
  width: 1196px;
  margin: 0 auto;
  display: flex;
  .conter {
    width: 100%;
    border: 1px solid #ccc;
    background: #fff;
    padding: 20px;
  }
}

::v-deep .el-input__inner {
  width: auto;
}
::v-deep .btn {
  //   width: 100%;
  margin-right: 200px;
  //   margin-left: none;
  text-align: center;
}
.title {
  margin: 20px auto;
  font-size: 20px;
  text-align: center;
  color: rgb(64, 158, 255);
}
</style>