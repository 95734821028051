<template>
  <div class="confirm">
    <Left></Left>
    <div class="right">
      <div class="conter">
        <div class="Line"></div>
        <div class="flex btn">
          <el-input v-model="config.no" placeholder="请输入合同编号"></el-input>
          <el-input
            v-model="config.sellerName"
            placeholder="请输入出卖人"
          ></el-input>
          <el-input
            v-model="config.buyerName"
            placeholder="请输入买受人"
          ></el-input>
          <el-select
            v-model="config.sendStatus"
            placeholder="请选择报送状态"
            clearable
          >
            <el-option
              v-for="item in Submission"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="flex btn">
          <!-- <el-select v-model="contractVal" placeholder="请选择合同类型">
            <el-option
              v-for="item in contractType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
          <el-select
            v-model="config.status"
            placeholder="请选择合同状态"
            clearable
          >
            <el-option
              v-for="item in ContractstatusL"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="config.contractSource"
            placeholder="请选择合同来源"
            clearable
          >
            <el-option
              v-for="item in ContractS"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div></div>
          <div></div>
        </div>
        <div style="text-align: right">
          <el-button type="primary" @click="CXClick" :disabled="status != 3"
            >查询</el-button
          >
          <el-button type="info" @click="ResetClick" :disabled="status != 3"
            >重置</el-button
          >
          <el-button
            type="primary"
            :disabled="status != 3"
            @click="primaryClick"
            >导出履约数据</el-button
          >
        </div>
        <div>
          <div style="color: red">模板下载、填写上传、快速报送履约数据</div>
          <div style="margin: 20px 0; display: flex">
            <el-button type="primary" :disabled="status != 3" @click="XZClick"
              >下载履约数据模板</el-button
            >
            <!-- <el-button type="primary" :disabled="status != 3"
              >   </el-button
            > -->
            <el-upload
              :disabled="status != 3"
              style="margin: 0 10px"
              class="upload-demo"
              action="#"
              multiple
              :limit="1"
              :before-upload="handleImageBeforeUp"
              :on-change="handleChange"
            >
              <el-button
                class="up_btn right"
                size="small"
                type="primary"
                :disabled="status != 3"
                >导入报送履约数据</el-button
              >
            </el-upload>
          </div>
        </div>
        <!-- <div class="flex btn">
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div>
            <el-button type="primary">查询</el-button>
            <el-button type="info">重置</el-button>
             <el-button type="primary">导出excel</el-button>
          </div>
        </div> -->
        <div class="Line"></div>
        <div>
          <div class="title">2022年度煤炭中长期月度履约数据报送列表</div>
          <div class="flex remarks">
            <div></div>
            <div style="color: #ccc">
              合同价格（单位：元/吨）、合同数量（单位：万吨）
            </div>
          </div>
        </div>

        <div style="margin: 20px auto">
          <el-table border :data="tableData" style="width: 100%">
            <el-table-column label="操作" width="120" align="center">
              <template slot-scope="scope">
                <el-button
                  :disabled="status != 3"
                  @click.native.prevent="deleteRow(scope.row, tableData)"
                  type="text"
                  size="small"
                >
                  报送
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              prop="manageAuditStatusLabel"
              label="后台审核状态"
              width="150"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  @click="StatusClick(scope, 1)"
                  type="text"
                  size="small"
                >
                  {{ scope.row.manageAuditStatusLabel }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="commissionAuditStatusLabel"
              label="发改委审核状态"
              width="150"
              align="center"
            >
              <!-- <template slot-scope="scope">
                {{ scope.row.commissionAuditStatusLabel }}
              </template> -->
              <template slot-scope="scope">
                <el-button
                  @click="StatusClick(scope, 2)"
                  type="text"
                  size="small"
                >
                  {{ scope.row.commissionAuditStatusLabel }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="sendStatusName"
              label="报送状态"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="contractSource"
              label="合同来源"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.contractSource | contractSource }}
              </template>
            </el-table-column>
            <el-table-column
              prop="no"
              label="合同编号"
              width="200"
              align="center"
            >
              <template slot-scope="scope">
                <div @click="NoClick(scope.row)" style="cursor: pointer">
                  {{ scope.row.no }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="sellerName"
              label="出卖人"
              width="250"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="buyerName"
              label="买受人"
              width="250"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="subtotal"
              label="合同数量"
              width="250"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="totalNumber"
              label="累计完成数量(万吨)"
              align="center"
              width="250"
            >
            </el-table-column>
            <el-table-column
              prop="scheduleNumber"
              label="进度履约率(%)"
              align="center"
              width="250"
            >
            </el-table-column>
            <!-- <el-table-column
              prop="consignor"
              label="发货人"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="consignee"
              label="收货人"
              width="120"
              align="center"
            >
            </el-table-column> -->
            <el-table-column
              prop="status"
              label="合同状态"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  @click="StatusClick(scope, 3)"
                  type="text"
                  size="small"
                >
                  {{ scope.row.status | status }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="basePrice"
              label="合同基础价格"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="typeOfShipping"
              label="运输方式"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.typeOfShipping | typeOfShipping }}
              </template>
            </el-table-column>
            <el-table-column
              prop="departure"
              label="发站"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="arrive"
              label="到站"
              width="120"
              align="center"
            >
            </el-table-column>
          </el-table>
          <div class="flex bottomContent">
            <div></div>
            <div>
              <el-pagination
                layout="total, prev, pager, next"
                @current-change="handleCurrentChange"
                :total="total"
                :page-size="config.pageSize"
                :current-page="config.pageNum"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  findContractList,
  exportContractList,
  getFileIdByType,
  exportSubmission,
  importSubmission
} from "@/api/Medium.js";
import { ossUploadFiletwo } from "@/api/public.js";
import Vue from "vue";
const { baseUrl } = require("@/config");
import * as types from "@/store/action-types";
export default {
  data() {
    return {
      baseUrl: baseUrl,
      config: {
        no: "",
        sellerName: "",
        buyerName: "",
        sendStatus: "",
        type: 5,
        status: "",
        contractSource: "",
        pageNum: 1,
        pageSize: 10,
      },
      currentPage1: 1000,
      numberVal: "",
      SellerVal: "",
      BuyerVal: "",
      SubmisVal: "",
      contractVal: "",
      ContractstatusVal: "",
      ContractSVal: "",
      //报送状态
      Submission: [
        {
          value: "1",
          label: "已完成",
        },
        {
          value: "2",
          label: "未完成",
        },
      ],
      //合同类型
      contractType: [
        {
          value: "1",
          label: "重点监管",
        },
        {
          value: "2",
          label: "一般监管",
        },
      ],
      //合同状态
      ContractstatusL: [
        {
          value: "1",
          label: "需方待确认",
        },
        {
          value: "2",
          label: "供方待确认",
        },
        {
          value: "3",
          label: "合同确认",
        },
        {
          value: "4",
          label: "需方已驳回",
        },
        {
          value: "5",
          label: "供方已驳回",
        },
      ],
      // 合同来源
      ContractS: [
        {
          value: "1",
          label: "中长期合同",
        },
        {
          value: "2",
          label: "应急保障合同",
        },
      ],
      options: [
        {
          value: "1",
          label: "黄金糕",
        },
        {
          value: "2",
          label: "双皮奶",
        },
        {
          value: "3",
          label: "蚵仔煎",
        },
        {
          value: "4",
          label: "龙须面",
        },
        {
          value: "5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      input: "",
      tableData: [],
      total: 0,
      status: null,
    };
  },
  components: {
    Left: () => import("../components/left1.vue"),
  },
  filters: {
    typeOfShipping(val) {
      let _data = {
        1: "铁水联运（国铁）",
        2: "铁路直达（国铁）",
        3: "自有铁路",
        4: "公路运输",
        5: "其他运输",
      };
      return _data[val];
    },
    auditStatus(val) {
      let _data = {
        1: "待审核",
        2: "已驳回",
        3: "已通过",
      };
      return _data[val];
    },
    commissionAuditStatus(val) {
      let _data = {
        1: "待审核",
        2: "已驳回",
      };
      return _data[val];
    },
    contractSource(val) {
      let _data = {
        1: "中长期合同",
        2: "应急保障合同",
      };
      return _data[val];
    },
    status(val) {
      let _data = {
        1: "需方待确认",
        2: "供方待确认",
        3: "合同确认",
        4: "需方已驳回",
        5: "供方已驳回",
      };
      return _data[val];
    },
  },
  mounted() {
    this.status = Vue.ls.get(types.status);
    this.getlist();
  },
  methods: {
    StatusClick(e, val) {
      console.log(e.row);
      if(val==1){
        if (e.row.manageAuditReason != null&&e.row.manageAuditReason != ''&&e.row.manageAuditStatus=='2') {
          this.$message.error(e.row.manageAuditReason);
        }
      }
      if(val==2){
        if (e.row.commissionAuditReason != null&&e.row.commissionAuditReason != ''&&e.row.commissionAuditStatus=='2') {
          this.$message.error(e.row.commissionAuditReason);
        }
      }
      if (val == 3) {
        if (e.row.reason != null&&e.row.reason != ''&&e.row.status=='4') {
          this.$message.error(e.row.reason);
        }
      }
    },
    handleCurrentChange(e) {
      this.config.pageNum = e;
      this.getlist();
    },
    handleChange() {},
    handleImageBeforeUp(file) {
      // let xls = file.name.slice(-3);
      // let pdf = file.name.slice(-4);
      // let isJPG;
      // if (pdf === "docx") {
      //   isJPG = true;
      // } else {
      //   isJPG = false;
      // }
      let otherFiles = file;
      // FormData 对象
      var formData = new FormData();
      // 文件对象
      // let name = file.name.split(".")[0];
      formData.append("file", otherFiles);
      // if (isJPG) {
      importSubmission(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.getlist();
        }else{
          this.$message.error(res.message);
        }
      });
      // }else{
      //   this.$message.error('请上传格式为：docx的合同文件');
      // }
    },
    XZClick() {
      // let config = {
      //   type: 1,
      // };
      // type：file_template label：2
      this.config.type = "5";
      this.config.label = "2";
      exportSubmission(this.config).then((res) => {
        const blob = new Blob([res], {
          // 创建一个新的Blob对象来接收后端的文件,这里第一个参数必须是数组类型，否则下载必出错。
          type: "application/vnd.ms-excel", // type，表明该 Blob 对象所包含数据的 MIME 类型,这需要前后端统一规划
        });
        let link = document.createElement("a");
        let body = document.querySelector("body");
        link.href = window.URL.createObjectURL(blob); //
        link.style.display = "none"; // 让这个a标签不可见
        link.download = "2022年度煤炭中长期月度履约数据报送列表"; //文件名称
        body.appendChild(link);
        link.click(); // 创建了新的a标签之后模拟点击事件，开始传输文件
        body.removeChild(link); // 下载完成之后，移除按钮，垃圾回收，减少页面内存消耗
        window.URL.revokeObjectURL(link.href); // 移除之前使用createObjectURL创建的URL，垃圾回收
      });
    },
    primaryClick() {
      exportContractList(this.config).then((res) => {
        const blob = new Blob([res], {
          // 创建一个新的Blob对象来接收后端的文件,这里第一个参数必须是数组类型，否则下载必出错。
          type: "application/vnd.ms-excel", // type，表明该 Blob 对象所包含数据的 MIME 类型,这需要前后端统一规划
        });
        let link = document.createElement("a");
        let body = document.querySelector("body");
        link.href = window.URL.createObjectURL(blob); //
        link.style.display = "none"; // 让这个a标签不可见
        link.download = "2022年度煤炭中长期月度履约数据报送列表"; //文件名称
        body.appendChild(link);
        link.click(); // 创建了新的a标签之后模拟点击事件，开始传输文件
        body.removeChild(link); // 下载完成之后，移除按钮，垃圾回收，减少页面内存消耗
        window.URL.revokeObjectURL(link.href); // 移除之前使用createObjectURL创建的URL，垃圾回收
      });
    },
    NoClick(row) {
      console.log(row);
      this.$router.push({
        path: "/AddEnter",
        query: {
          id: row.id,
          type: 1,
        },
      });
    },
    getlist() {
      findContractList(this.config).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    CXClick() {
      this.getlist();
    },
    ResetClick() {
      this.config = {
        no: "",
        sellerName: "",
        buyerName: "",
        sendStatus: "",
        type: 5,
        status: "",
        contractSource: "",
        pageNum: 1,
        pageSize: 10,
      };
      this.getlist();
    },
    deleteRow(row) {
      console.log(row);
      this.$router.push({
        path: "/Submitted",
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm {
  width: 1196px;
  margin: 0 auto;
  display: flex;
  .right {
    width: 970px;
    font-size: 14px;
    .conter {
      width: 100%;
      border: 1px solid #ccc;
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
  }
}
.bottomContent {
  margin-top: 20px;
}
.remarks {
  margin-top: 20px;
  align-items: center;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.title {
  text-align: center;
  margin: 30px auto 0;
  font-size: 20px;
}
.Line {
  width: 100%;
  height: 1px;
  background: #ccc;
}
.btn {
  margin: 20px 0;
  div {
    width: 200px;
  }
}
.pageina {
  text-align: right;
  margin-top: 10px;
}
.upload-demo button {
  width: 150px !important;
  height: 40px;
}
</style>