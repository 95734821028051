<template>
  <div class="confirm">
    <Left></Left>
    <div class="right">
      <div class="conter">
        <div class="Line"></div>
        <div class="flex btn">
          <el-input v-model="config.no" placeholder="请输入合同编号"></el-input>
          <el-input
            v-model="config.sellerName"
            placeholder="请输入出卖人"
            style="margin: 0 10px"
          ></el-input>
          <el-input
            v-model="config.buyerName"
            placeholder="请输入买受人"
            style="margin: 0 10px"
          ></el-input>
          <el-select
            v-model="config.whetherOffline"
            placeholder="线下实际经济合同是否已签订"
          >
            <el-option
              v-for="item in economy"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="flex btn">
          <el-select v-model="config.status" placeholder="合同状态">
            <el-option
              v-for="item in Contract"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- <el-select v-model="CountersignVal" placeholder="是否补签">
            <el-option
              v-for="item in Countersign"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
          <div>
            <el-button type="primary" @click="CXClick" :disabled="status != 3"
              >查询</el-button
            >
            <el-button type="info" @click="ResetClick" :disabled="status != 3"
              >重置</el-button
            >
            <el-button
              type="primary"
              :disabled="status != 3"
              @click="primaryClick"
              >导出excel</el-button
            >
          </div>
        </div>
        <div class="Line"></div>
        <div>
          <div class="title">2022年度煤炭中长期合同查询列表</div>
          <div class="flex remarks">
            <div></div>
            <div style="color: #ccc">
              合同价格（单位：元/吨）、合同数量（单位：万吨）
            </div>
          </div>
        </div>

        <div style="margin: 20px auto">
          <el-table border :data="tableData" style="width: 100%">
            <el-table-column
              prop="no"
              label="合同编号"
              width="150"
              align="center"
            >
             <template slot-scope="scope">
                <div @click="NoClick(scope.row)" style="cursor: pointer">
                  {{ scope.row.no }}
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="name"
              label="名录ID"
              width="120"
              align="center"
            >
            </el-table-column> -->
            <el-table-column
              prop="whetherLetterUrl"
              label="供方承诺状态"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.whetherLetterUrl | whetherLetterUrl }}
              </template>
            </el-table-column>
            <el-table-column
              prop="whetherOtherPartyLetterUrl"
              label="需方承诺状态"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                {{
                  scope.row.whetherOtherPartyLetterUrl
                    | whetherOtherPartyLetterUrl
                }}
              </template>
            </el-table-column>

            <!-- <el-table-column
              prop="auditStatus"
              label="核查审核状态"
              width="300"
              align="center"
            >
            </el-table-column> -->
            <el-table-column
              prop="whetherTemplate"
              label="合同条款状态"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.whetherTemplate | whetherTemplate }}
              </template>
            </el-table-column>
            <el-table-column
              prop="whetherSpecificProvisionsUrl"
              label="欠一补三状态"
              width="120"
              align="center"
            >
            <template slot-scope="scope">
                {{ scope.row.whetherSpecificProvisionsUrl | whetherSpecificProvisionsUrl }}
              </template>
            </el-table-column>
            <el-table-column
              prop="Coalsource"
              label="煤源用煤信息状态"
              width="200"
              align="center"
            >
            </el-table-column>
            <!-- <el-table-column
              prop="reason"
              label="不规范原因"
              width="120"
              align="center"
            >
            </el-table-column> -->
            <el-table-column
              prop="whetherOffline"
              label="线下实际经济合同是否已签订"
              width="250"
              align="center"
            >
            <template slot-scope="scope">
                {{ scope.row.whetherOffline | whetherOffline }}
              </template>
            </el-table-column>
            <el-table-column
              prop="SignChangeType"
              label="换签类型"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="Signchangestatus"
              label="换签状态"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="ReSignOrNot"
              label="是否补签"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="sellerName"
              label="出卖人"
              width="250"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="buyerName"
              label="买受人"
              width="250"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="subtotal"
              label="合同数量"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="Transport"
              label="运力合同数量"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="forwardingUnit"
              label="发货人"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="consignee"
              label="收货人"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="statusLabel"
              label="合同状态"
              width="120"
              align="center"
            >
             <template slot-scope="scope">
                <el-button @click="StatusClick(scope,1)" type="text" size="small">
                  {{ scope.row.statusLabel }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="auditStatus"
              label="审核状态"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  @click="StatusClick(scope, 2)"
                  type="text"
                  size="small"
                >
                  {{ scope.row.auditStatus | auditStatus }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="wetherAbandonment"
              label="废弃状态"
              width="120"
              align="center"
            >
              <template slot-scope="scope"> 未废弃 </template>
            </el-table-column>
            <el-table-column
              prop="OfflineContract"
              label="线下合同状态"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="basePrice"
              label="合同基础价格"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="typeOfShipping"
              label="运输方式"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.typeOfShipping | typeOfShipping }}
              </template>
            </el-table-column>
            <el-table-column
              prop="departure"
              label="发站"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="arrive"
              label="到站"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="dataSources"
              label="数据来源省份"
              width="120"
              align="center"
            >
            </el-table-column>
          </el-table>
          <div class="flex bottomContent">
            <div style="color: red; font-size: 12px">
              <div>备注：请按照经济合同、履约承诺规范标准进行自查。</div>
              <div>
                1、经济合同规范标准，要有明确的合同数量、质量标准、价格水平或价格机制（包括：“基准价+浮动价”或“自主协商价”），及违约条款等要素齐全的煤炭中长期合同；录入的每笔合同都要对应上传线下经济合同。
              </div>
              <div>
                2、履约承诺规范标准，履约承诺中的合同编号要与对应录入的合同编号一致；履约承诺要加盖公章或合同章；录入的每笔合同都要对应上传履约承诺。
              </div>
            </div>
            <div>
              <!-- @size-change="handleSizeChange"
              @current-change="handleCurrentChange" -->
              <el-pagination
                layout="total, prev, pager, next"
                 @current-change="handleCurrentChange"
                :total="total"
                :page-size="config.pageSize"
                :current-page="config.pageNum"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { findContractList, exportContractList } from "@/api/Medium.js";
import Vue from "vue";
import * as types from "@/store/action-types";
export default {
  data() {
    return {
      config: {
        pageNum: 1,
        pageSize: 10,
        type: 4,
        no: "",
        sellerName: "",
        buyerNameL: "",
        whetherOffline: null,
        status: null,
      },
      currentPage1: 1000,
      numberVal: "",
      SellerVal: "",
      BuyerVal: "",
      economyVal: "",
      ContractVal: "",
      CountersignVal: "",
      economy: [
        {
          value: "true",
          label: "是",
        },
        {
          value: "false",
          label: "否",
        },
      ],
      Contract: [
        {
          value: "1",
          label: "需方待确认",
        },
        {
          value: "2",
          label: "供方待确认",
        },
        {
          value: "3",
          label: "合同确认",
        },
        {
          value: "4",
          label: "需方已驳回",
        },
        {
          value: "5",
          label: "供方已驳回",
        },
      ],
      Countersign: [
        {
          value: "true",
          label: "是",
        },
        {
          value: "false",
          label: "否",
        },
      ],
      value: "",
      input: "",
      tableData: [],
      total: 0,
      status: null,
    };
  },
  components: {
    Left: () => import("../components/left1.vue"),
  },
  mounted() {
    this.status = Vue.ls.get(types.status);
    this.getlist();
  },
  filters: {
    status(val) {
      let _data = {
        1: "需方待确认",
        2: "供方待确认",
        3: "合同确认",
        5: "供方已确认",
        6: "需方已驳回",
      };
      return _data[val];
    },
    whetherLetterUrl(val) {
      let _data = {
        true: "已上传",
        false: "未上传",
      };
      return _data[val];
    },
    whetherOtherPartyLetterUrl(val) {
      let _data = {
        true: "已上传",
        false: "未上传",
      };
      return _data[val];
    },
    whetherTemplate(val) {
      let _data = {
        true: "已上传",
        false: "未上传",
      };
      return _data[val];
    },
    whetherSpecificProvisionsUrl(val) {
      let _data = {
        true: "已上传",
        false: "未上传",
      };
      return _data[val];
    },
    whetherOffline(val) {
      let _data = {
        true: "已上传",
        false: "未上传",
      };
      return _data[val];
    },
    auditStatus(val) {
      let _data = {
        1: "待审核",
        2: "已驳回",
        3: "已通过",
      };
      return _data[val];
    },
    typeOfShipping(val) {
      let _data = {
        1: "铁水联运（国铁）",
        2: "铁路直达（国铁）",
        3: "自有铁路",
        4: "公路运输",
        5: "其他运输",
      };
      return _data[val];
    },
  },
  methods: {
    StatusClick(e,val) {
      console.log(e.row)
      if (val == 1) {
        if (e.row.reason != null&&e.row.reason !=''&&e.row.status=='4') {
          this.$message.error(e.row.reason);
        }
      }
      if(val==2){
        if (e.row.auditReason != null&&e.row.auditReason !='') {
          this.$message.error(e.row.auditReason);
        }
      }
    },
    NoClick(row) {
      console.log(row);
      this.$router.push({
        path: "/AddEnter",
        query: {
          id: row.id,
          type:1
        },
      });
    },
    handleCurrentChange(e){
      this.config.pageNum=e
      this.getlist();
    },
    primaryClick() {
      exportContractList(this.config).then((res) => {
        const blob = new Blob([res], {
          // 创建一个新的Blob对象来接收后端的文件,这里第一个参数必须是数组类型，否则下载必出错。
          type: "application/vnd.ms-excel", // type，表明该 Blob 对象所包含数据的 MIME 类型,这需要前后端统一规划
        });
        let link = document.createElement("a");
        let body = document.querySelector("body");
        link.href = window.URL.createObjectURL(blob); //
        link.style.display = "none"; // 让这个a标签不可见
        link.download = "2022年度煤炭中长期合同查询列表"; //文件名称
        body.appendChild(link);
        link.click(); // 创建了新的a标签之后模拟点击事件，开始传输文件
        body.removeChild(link); // 下载完成之后，移除按钮，垃圾回收，减少页面内存消耗
        window.URL.revokeObjectURL(link.href); // 移除之前使用createObjectURL创建的URL，垃圾回收
      });
    },
    getlist() {
      findContractList(this.config).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    CXClick() {
      this.getlist();
    },
    ResetClick() {
      this.config = {
        pageNum: 1,
        pageSize: 10,
        type: 4,
        no: "",
        sellerName: "",
        buyerNameL: "",
        whetherOffline: null,
        status: null,
      };
      this.getlist();
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm {
  width: 1196px;
  margin: 0 auto;
  display: flex;
  .right {
    width: 970px;
    .conter {
      width: 100%;
      border: 1px solid #ccc;
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
  }
}
.bottomContent {
  margin-top: 20px;
}
.remarks {
  margin-top: 20px;
  align-items: center;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.title {
  text-align: center;
  margin: 30px auto 0;
  font-size: 20px;
}
.Line {
  width: 100%;
  height: 1px;
  background: #ccc;
}
.btn {
  margin: 20px 0;
  div {
    width: 300px;
  }
}
.pageina {
  text-align: right;
  margin-top: 10px;
}
</style>