<template>
  <div class="addenter">
    <div class="conter">
      <div class="conter_o">
        <div class="titlt">01 合同基础信息</div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <div class="flex">
            <el-form-item label="【出卖人】" prop="sellerName">
              <el-autocomplete
                v-model="ruleForm.sellerName"
                :fetch-suggestions="querySearchAsync"
                @select="handleSelect"
                placeholder="请输入内容"
                :disabled="istrue"
              >
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="【买受人】" prop="buyerName">
              <el-autocomplete
                v-model="ruleForm.buyerName"
                :fetch-suggestions="querySearchAsyncs"
                @select="handleSelects"
                placeholder="请输入内容"
                :disabled="istrue"
              >
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="【承运人】" prop="carrier">
              <el-input
                v-model="ruleForm.carrier"
                placeholder="承运人"
                :disabled="istrue"
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item label="【合同签订日期】" prop="signDate">
              <!-- <el-input v-model="ruleForm.name"></el-input> -->
              <el-date-picker
                v-model="ruleForm.signDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                :disabled="istrue"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="【合同签订地点】" prop="positionId">
              <!-- @change="selectChange('deliveryPlace')" -->
              <el-cascader
                :disabled="istrue"
                @change="positionIdChange"
                v-model="ruleForm.positionId"
                :options="citys"
                placeholder="请选择合同签订地点"
                :props="{
                  value: 'id',
                  label: 'name',
                }"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="【合同类型】" prop="contractType">
              <el-select
                v-model="ruleForm.contractType"
                placeholder="请选择"
                :disabled="istrue"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item label="【合同起始日期】" prop="startDate">
              <el-date-picker
                :disabled="istrue"
                v-model="ruleForm.startDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="【合同形式】" prop="contractForm">
              <el-select
                v-model="ruleForm.contractForm"
                placeholder="请选择"
                :disabled="istrue"
              >
                <el-option
                  v-for="item in option"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="【运输方式】" prop="typeOfShipping">
              <el-select
                v-model="ruleForm.typeOfShipping"
                placeholder="请选择"
                :disabled="istrue"
              >
                <el-option
                  v-for="item in transport"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item label="【合同结束日期】" prop="endDate">
              <el-date-picker
                :disabled="istrue"
                v-model="ruleForm.endDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="【所属行业】" prop="industryType">
              <el-select
                v-model="ruleForm.industryType"
                placeholder="请选择"
                :disabled="istrue"
              >
                <el-option
                  v-for="item in industry"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="【交货方式】" prop="deliveryMethod">
              <el-select
                v-model="ruleForm.deliveryMethod"
                placeholder="请选择"
                :disabled="istrue"
              >
                <el-option
                  v-for="item in delivery"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item label="【合同价格机制】" prop="priceMechanism">
              <el-select
                v-model="ruleForm.priceMechanism"
                placeholder="请选择"
                :disabled="istrue"
              >
                <el-option
                  v-for="item in priceMechanism"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="【合同基础价格】" prop="basePrice">
              <el-input
                :disabled="istrue"
                v-model="ruleForm.basePrice"
                placeholder="元/吨"
              ></el-input>
            </el-form-item>
            <el-form-item label="【价格类型】" prop="priceType">
              <el-select
                v-model="ruleForm.priceType"
                placeholder="请选择"
                :disabled="istrue"
              >
                <el-option
                  v-for="item in PriceType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <el-form-item
            label="【价格机制具体条款】"
            prop="priceMechanismClause"
          >
            <el-input
              :disabled="istrue"
              type="textarea"
              v-model="ruleForm.priceMechanismClause"
              :autosize="{ minRows: 2, maxRows: 4 }"
              maxlength="500"
              placeholder="请输入具体条款,最多500字"
            >
            </el-input>
          </el-form-item>
          <div class="flexs">
            <el-form-item
              label="【是否为3年及以上中长期合同】"
              prop="whetherThreeYear"
            >
              <el-select
                :disabled="istrue"
                v-model="ruleForm.whetherThreeYear"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in whether"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              v-if="ruleForm.whetherThreeYear"
              style="margin-left: 20px"
              label="【合同总量】"
              prop="count"
            >
              <el-input
                v-model="ruleForm.count"
                placeholder="万吨"
                :disabled="istrue"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item label="【欠一补三具体条款】" prop="specificProvisions">
            <el-input
              v-model="ruleForm.specificProvisions"
              :disabled="istrue"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              maxlength="500"
              placeholder="请输入具体条款,最多500字"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="【仲裁条款】" prop="arbitrationClause">
            <el-input
              v-model="ruleForm.arbitrationClause"
              :disabled="istrue"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              maxlength="500"
              placeholder="请输入仲裁条款,最多500字"
            >
            </el-input>
          </el-form-item>
          <div class="conter_t">
            <div class="titlt">02 煤源信息</div>
            <div style="margin-top: 10px">
              <el-button
                type="primary"
                style="margin: 10px 0"
                plain
                @click="addsource"
                :disabled="istrue"
                >添加</el-button
              >
              <el-table
                :data="contractCoalDTOList"
                border
                style="width: 100%; margin-top: 20px"
              >
                <el-table-column prop="name" label="煤矿名称" align="center">
                </el-table-column>
                <el-table-column
                  prop="number"
                  label="煤炭数量(万吨)"
                  align="center"
                >
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center">
                  <template slot-scope="scope">
                    <el-button
                      @click="
                        handleClick(
                          scope.$index,
                          contractCoalDTOList,
                          scope.row
                        )
                      "
                      type="text"
                      size="small"
                      :disabled="istrue"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <div class="remarks">煤炭数量累计总和:{{ Coalform.sum }}万吨</div>
            </div>
          </div>
          <div class="conter_t">
            <div class="titlt">03 用煤企业信息(电厂)</div>
            <div style="margin-top: 10px">
              <el-button
                :disabled="istrue"
                type="primary"
                style="margin: 10px 0"
                plain
                @click="enterprises"
                >添加</el-button
              >
              <el-table
                :data="coalEnterprisesDTOS"
                border
                style="width: 100%; margin-top: 20px"
              >
                <el-table-column
                  prop="name"
                  label="用煤企业名称"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="districtCountyName"
                  label="用煤省份"
                  align="center"
                >
                </el-table-column>
                <el-table-column prop="count" label="用煤数量" align="center">
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center">
                  <template slot-scope="scope">
                    <el-button
                      @click="
                        handleClicks(
                          scope.$index,
                          coalEnterprisesDTOS,
                          scope.row
                        )
                      "
                      type="text"
                      :disabled="istrue"
                      size="small"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <div class="remarks">
                用煤数量累计总和:{{ Informationform.sum }}万吨
              </div>
            </div>
          </div>
          <div class="conter_f">
            <div class="titlt">04 2022年度合同数量,品种,运输信息</div>
            <div class="flex" style="margin-top: 20px">
              <el-form-item label="【发货单位】" prop="forwardingUnit">
                <!-- <el-input
                  v-model="ruleForm.forwardingUnit"
                  placeholder="请输入发货单位"
                ></el-input> -->
                <el-autocomplete
                  :disabled="istrue"
                  v-model="ruleForm.forwardingUnit"
                  :fetch-suggestions="dingUnitAsync"
                  @select="dingUnitAsyncSelect"
                  placeholder="请输入内容"
                >
                </el-autocomplete>
              </el-form-item>
              <el-form-item label="【发货地址】" prop="fuDistrictCountyId">
                <el-cascader
                  :disabled="istrue"
                  @change="fuDistrictCountyIdChange"
                  v-model="ruleForm.fuDistrictCountyId"
                  :options="citys"
                  placeholder="请选择发货地址"
                  :props="{
                    value: 'id',
                    label: 'name',
                  }"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="【联系方式】" prop="fuMobile">
                <el-input
                  :disabled="istrue"
                  v-model="ruleForm.fuMobile"
                  placeholder="请输入联系方式"
                ></el-input>
              </el-form-item>
            </div>
            <div class="flex">
              <el-form-item label="【收货单位】" prop="consignee">
                <!-- <el-input
                  v-model="ruleForm.consignee"
                  placeholder="请输入收货单位"
                ></el-input> -->
                <el-autocomplete
                  :disabled="istrue"
                  v-model="ruleForm.consignee"
                  :fetch-suggestions="ConsigneeAsync"
                  @select="ConsigneeSelect"
                  placeholder="请输入内容"
                >
                </el-autocomplete>
              </el-form-item>
              <el-form-item label="【收货地址】" prop="coDistrictCountyId">
                <el-cascader
                  :disabled="istrue"
                  @change="coDistrictCountyIdChange"
                  v-model="ruleForm.coDistrictCountyId"
                  :options="citys"
                  placeholder="请选择收货地址"
                  :props="{
                    value: 'id',
                    label: 'name',
                  }"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="【联系方式】" prop="coMobile">
                <el-input
                  :disabled="istrue"
                  v-model="ruleForm.coMobile"
                  placeholder="请输入联系方式"
                ></el-input>
              </el-form-item>
            </div>
            <div>
              <el-button
                :disabled="istrue"
                type="primary"
                @click="addTable"
                style="margin: 10px 0"
                plain
                >添加</el-button
              >
              <div class="flex">
                <el-table :data="contractOtherInfoDTO" border>
                  <el-table-column prop="departure" label="发站" align="center">
                  </el-table-column>
                  <el-table-column prop="arrive" label="到站" align="center">
                  </el-table-column>
                  <el-table-column prop="port" label="港口" align="center">
                  </el-table-column>
                  <el-table-column
                    prop="description"
                    label="品种规格"
                    align="center"
                  >
                  </el-table-column>
                </el-table>
                <el-table :data="contractOtherInfoDTO" border>
                  <el-table-column prop="subtotal" label="小计" align="center">
                  </el-table-column>
                  <el-table-column prop="januay" label="1月" align="center">
                  </el-table-column>
                  <el-table-column prop="february" label="2月" align="center">
                  </el-table-column>
                  <el-table-column prop="march" label="3月" align="center">
                  </el-table-column>
                  <el-table-column prop="april" label="4月" align="center">
                  </el-table-column>
                  <el-table-column prop="may" label="5月" align="center">
                  </el-table-column>
                  <el-table-column prop="june" label="6月" align="center">
                  </el-table-column>
                  <el-table-column prop="july" label="7月" align="center">
                  </el-table-column>
                  <el-table-column prop="august" label="8月" align="center">
                  </el-table-column>
                  <el-table-column prop="september" label="9月" align="center">
                  </el-table-column>
                  <el-table-column prop="october" label="10月" align="center">
                  </el-table-column>
                  <el-table-column prop="november" label="11月" align="center">
                  </el-table-column>
                  <el-table-column prop="december" label="12月" align="center">
                  </el-table-column>
                  <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                      <el-button
                        @click="
                          handleClickss(
                            scope.$index,
                            contractOtherInfoDTO,
                            scope.row
                          )
                        "
                        type="text"
                        size="small"
                        :disabled="istrue"
                        >删除</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>

            <div class="flex" style="margin-top: 20px">
              <el-form-item label="【合同条款模板】" prop="templeteId">
                <!-- <el-input
                  v-model="ruleForm.templeteId"
                  placeholder="请输入关键字检索"
                ></el-input> -->
                <el-select
                  :disabled="istrue"
                  v-model="ruleForm.templeteId"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in templeteIdOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <el-button
                  :disabled="istrue"
                  size="small"
                  type="primary"
                  @click="AddTemplateClick"
                  style="margin-left: 10px"
                  class="btn_size"
                  >模板维护</el-button
                >
              </el-form-item>
              <el-form-item label="【线下已签订合同上传】" prop="offlineName">
                <el-input v-model="ruleForm.offlineName" disabled></el-input>
                <!-- <el-button type="primary" style="margin-left: 10px"
                  >上传</el-button
                > -->
                <el-upload
                  style="margin-left: 10px"
                  class="upload-demo"
                  action="#"
                  multiple
                  :limit="1"
                  :before-upload="handleImageBeforeUpload"
                  :on-change="handleChange"
                >
                  <el-button
                    class="up_btn right"
                    size="small"
                    type="primary"
                    :disabled="istrue"
                    >上传</el-button
                  >
                </el-upload>
              </el-form-item>
            </div>
            <div class="flex">
              <el-form-item label="【结算单据上传】" prop="settleName">
                <el-input v-model="ruleForm.settleName" disabled></el-input>
                <!-- <el-button type="primary" style="margin-left: 10px"
                  >上传</el-button
                > -->
                <el-upload
                  style="margin-left: 10px"
                  class="upload-demo"
                  action="#"
                  multiple
                  :limit="1"
                  :before-upload="settleUpload"
                  :on-change="handleChange"
                >
                  <el-button
                    class="up_btn right"
                    size="small"
                    type="primary"
                    :disabled="istrue"
                    >上传</el-button
                  >
                </el-upload>
              </el-form-item>
              <el-form-item label="【交收单据上传】" prop="settlementName">
                <el-input v-model="ruleForm.settlementName" disabled></el-input>
                <!-- <el-button type="primary" style="margin-left: 10px"
                  >上传</el-button
                > -->
                <el-upload
                  style="margin-left: 10px"
                  class="upload-demo"
                  action="#"
                  multiple
                  :limit="1"
                  :before-upload="offlineUpload"
                  :on-change="handleChange"
                >
                  <el-button
                    class="up_btn right"
                    size="small"
                    type="primary"
                    :disabled="istrue"
                    >上传</el-button
                  >
                </el-upload>
              </el-form-item>
            </div>
            <div class="flex">
              <el-form-item
                label='【"欠一补三"补充协议上传】'
                prop="specificProvisionsName"
              >
                <el-input
                  v-model="ruleForm.specificProvisionsName"
                  disabled
                ></el-input>
                <!-- <el-button type="primary" style="margin-left: 10px"
                  >上传</el-button
                > -->
                <el-upload
                  style="margin-left: 10px"
                  class="upload-demo"
                  action="#"
                  multiple
                  :limit="1"
                  :before-upload="handleImageBeforeUploads"
                  :on-change="handleChange"
                >
                  <el-button
                    class="up_btn right"
                    size="small"
                    type="primary"
                    :disabled="istrue"
                    >上传</el-button
                  >
                </el-upload>
              </el-form-item>
              <el-form-item label="【履约承诺书上传】" prop="letterName">
                <el-input v-model="ruleForm.letterName" disabled></el-input>
                <!-- <el-button type="primary" style="margin-left: 10px"
                  >上传</el-button
                > -->
                <el-upload
                  style="margin: 0 10px"
                  class="upload-demo"
                  action="#"
                  multiple
                  :limit="1"
                  :before-upload="handleImageBeforeUp"
                  :on-change="handleChange"
                >
                  <el-button
                    class="up_btn right"
                    size="small"
                    type="primary"
                    :disabled="istrue"
                    >上传</el-button
                  >
                </el-upload>
                <el-button
                  type="primary"
                  size="small"
                  class="btn_size"
                  @click="XZClick"
                  :disabled="istrue"
                  >下载模板</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click="SigningClick"
                  class="btn_size"
                  :disabled="istrue"
                  >在线签章签订</el-button
                >
              </el-form-item>
            </div>
            <div class="flexs">
              <div style="width: 525px"></div>
              <div style="color: red">
                <div>
                  请您点击“下载模板”下载履约承诺书模板，填写日期并盖章后进行上传！
                </div>
                <div>或使用电子签章在线签订！</div>
              </div>
            </div>
          </div>
          <el-form-item>
            <div class="btn">
              <el-button
                v-if="!istrue"
                type="primary"
                style="margin-right: 30px"
                @click="submitForm('ruleForm')"
                >录入并提交</el-button
              >
              <el-button
                v-if="!istrue"
                style="margin-left: 30px"
                @click="resetForm('ruleForm')"
                >关闭</el-button
              >
              <el-button
                v-if="istrue"
                style="margin-left: 30px"
                @click="resetForm('ruleForm')"
                >返回</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-dialog title="添加煤源信息" :visible.sync="dialogFormVisible">
      <el-form
        :model="Coalform"
        label-width="120px"
        :rules="CoalformRlues"
        ref="Coalform"
      >
        <el-form-item label="煤矿名称" prop="name">
          <!-- <el-input v-model="Coalform.name" autocomplete="off"></el-input> -->
          <el-autocomplete
            v-model="Coalform.name"
            :fetch-suggestions="CoalformF"
            @select="CoalformSelectForm"
            placeholder="请输入内容"
          >
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="煤炭数量(万吨)" prop="number">
          <el-input
            @input="limitInput($event, 'Coalform.number')"
            v-model="Coalform.number"
            autocomplete="off"
            maxlength="10"
            type="number"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleDe">取 消</el-button>
        <el-button type="primary" @click="AddCoalform('Coalform')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="添加用煤企业信息" :visible.sync="coMobileVile">
      <el-form
        :model="Informationform"
        label-width="120px"
        :rules="InformationformRules"
        ref="Informationform"
      >
        <el-form-item label="用煤企业名称" prop="name">
          <!-- <el-input
            v-model="Informationform.name"
            autocomplete="off"
          ></el-input> -->
          <el-autocomplete
            v-model="Informationform.name"
            :fetch-suggestions="InformationformF"
            @select="handleSelectForm"
            placeholder="请输入内容"
          >
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="用煤省份" prop="districtCountyId">
          <!-- <el-input
            v-model="Informationform.districtCountyId"
            autocomplete="off"
          ></el-input> -->
          <el-cascader
            v-model="Informationform.districtCountyId"
            @change="districtCountyIdChange"
            :options="citys"
            ref="areaNames"
            placeholder="请选择用煤省份"
            :props="{
              value: 'id',
              label: 'name',
            }"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="用煤数量(万吨)" prop="count">
          <el-input
            maxlength="10"
            v-model="Informationform.count"
            @input="limitInput($event, 'Informationform.count')"
            type="number"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="coMobileVileDe">取 消</el-button>
        <el-button type="primary" @click="AddInformation('Informationform')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="2022年度合同数量、品种、运输信息添加"
      :visible.sync="Transportation"
      style="width: 100%; margin: auto 0"
    >
      <el-form
        :model="Transportationform"
        label-width="120px"
        :rules="TransportationformRu"
        ref="Transportationform"
      >
        <div class="flex">
          <el-form-item label="发站" prop="departure">
            <el-input
              v-model="Transportationform.departure"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="到站" prop="arrive">
            <el-input
              v-model="Transportationform.arrive"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="港口" prop="port">
            <el-input
              v-model="Transportationform.port"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="品种规格" prop="description">
            <!-- <el-input
              v-model="Transportationform.description"
              autocomplete="off"
            ></el-input> -->
            <el-select
              v-model="Transportationform.description"
              @visible-change="VisiChange"
              placeholder="请选择"
            >
              <el-option
                v-for="item in Transportationoptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <div class="CoalQuantity">分月煤炭数量 (单位:万吨)</div>
          <div class="flex" style="margin-top: 20px">
            <el-form-item label="小计" prop="subtotal">
              <el-input
                v-model="Transportationform.subtotal"
                autocomplete="off"
                @blur="countInput(1)"
              ></el-input>
            </el-form-item>
            <div></div>
          </div>
        </div>
        <div class="flex">
          <el-form-item label="1月" prop="januay">
            <el-input
              v-model="Transportationform.januay"
              autocomplete="off"
              @blur="countInput(2)"
            ></el-input>
          </el-form-item>
          <el-form-item label="2月" prop="february">
            <el-input
              v-model="Transportationform.february"
              autocomplete="off"
              @blur="countInput(2)"
            ></el-input>
          </el-form-item>
          <el-form-item label="3月" prop="march">
            <el-input
              v-model="Transportationform.march"
              autocomplete="off"
              @blur="countInput(2)"
            ></el-input>
          </el-form-item>
          <el-form-item label="4月" prop="april">
            <el-input
              v-model="Transportationform.april"
              autocomplete="off"
              @blur="countInput(2)"
            ></el-input>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="5月" prop="may">
            <el-input
              v-model="Transportationform.may"
              autocomplete="off"
              @blur="countInput(2)"
            ></el-input>
          </el-form-item>
          <el-form-item label="6月" prop="june">
            <el-input
              v-model="Transportationform.june"
              autocomplete="off"
              @blur="countInput(2)"
            ></el-input>
          </el-form-item>
          <el-form-item label="7月" prop="july">
            <el-input
              v-model="Transportationform.july"
              autocomplete="off"
              @blur="countInput(2)"
            ></el-input>
          </el-form-item>
          <el-form-item label="8月" prop="august">
            <el-input
              v-model="Transportationform.august"
              autocomplete="off"
              @blur="countInput(2)"
            ></el-input>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="9月" prop="september">
            <el-input
              v-model="Transportationform.september"
              autocomplete="off"
              @blur="countInput(2)"
            ></el-input>
          </el-form-item>
          <el-form-item label="10月" prop="october">
            <el-input
              v-model="Transportationform.october"
              autocomplete="off"
              @blur="countInput(2)"
            ></el-input>
          </el-form-item>
          <el-form-item label="11月" prop="november">
            <el-input
              v-model="Transportationform.november"
              autocomplete="off"
              @blur="countInput(2)"
            ></el-input>
          </el-form-item>
          <el-form-item label="12月" prop="december">
            <el-input
              v-model="Transportationform.december"
              autocomplete="off"
              @blur="countInput(2)"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="Transportation = false">取 消</el-button>
        <el-button
          type="primary"
          @click="TransportationClick('Transportationform')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import citys from "@/assets/json/citys";
const { baseUrl } = require("@/config");
import floatCompute from "@/utils/floatCompute.js";
import {
  userList,
  findVarietiesAndType,
  findContractTemplateList,
  addAndEditContract,
  getContractInfo,
  executeMonthCount,
  getFileIdByType,
  findCoalMineList,
  getTreeV2,
  getContractNo
} from "@/api/Medium.js";
import { ossUploadFiletwo } from "@/api/public.js";
export default {
  data() {
    return {
      floatCompute: floatCompute,
      baseUrl: baseUrl,
      contractCoalDTOList: [],
      coalEnterprisesDTOS: [],
      contractOtherInfoDTO: [],
      Transportation: false,
      tableData: [],
      citys: [],
      SubmissionVal: "",
      Submission: [
        {
          value: "1",
          label: "沫煤",
        },
        {
          value: "2",
          label: "中煤",
        },
        {
          value: "3",
          label: "精美",
        },
        {
          value: "4",
          label: "烟煤",
        },
        {
          value: "5",
          label: "无烟喷吹煤",
        },
        {
          value: "6",
          label: "贫瘦喷吹煤",
        },
      ],
      whether: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      priceMechanism: [
        {
          value: "1",
          label: "基准价+浮动价",
        },
        {
          value: "2",
          label: "双方自主商定",
        },
      ],
      PriceType: [
        {
          value: "1",
          label: "坑口含税价",
        },
      ],
      delivery: [
        {
          value: "1",
          label: "场地自提",
        },
        {
          value: "2",
          label: "发站交货",
        },
        {
          value: "3",
          label: "到站交货",
        },
        {
          value: "4",
          label: "中转港场地交货",
        },
        {
          value: "5",
          label: "离岸平仓交货",
        },
        {
          value: "6",
          label: "到达港交货",
        },
        {
          value: "7",
          label: "车板交货",
        },
        {
          value: "8",
          label: "到厂(电厂)交货",
        },
      ],
      industry: [
        {
          value: "1",
          label: "电力",
        },
        {
          value: "2",
          label: "冶金",
        },
        {
          value: "3",
          label: "建材",
        },
        {
          value: "4",
          label: "化工",
        },
        {
          value: "5",
          label: "其他",
        },
      ],
      option: [
        {
          value: "1",
          label: "跨省衔接",
        },
        {
          value: "2",
          label: "省内交易",
        },
      ],
      transport: [
        {
          value: "1",
          label: "铁水联运(国铁)",
        },
        {
          value: "2",
          label: "铁路直达(国铁)",
        },
        {
          value: "3",
          label: "自有铁路",
        },
        {
          value: "4",
          label: "公路运输",
        },
        {
          value: "5",
          label: "其他运输",
        },
      ],
      options: [
        {
          value: "1",
          label: "年度中长期合同",
        },
        {
          value: "2",
          label: "进口煤应急保障",
        },
        {
          value: "3",
          label: "重点贸易商保供合同",
        },
      ],
      ruleForm: {
        offlineName: "",
        specificProvisionsName: "",
        letterName: "",

        settleName: "",
        sellerId: "",
        buyerId: "",
        name: "",
        coMobile: "",
        coDistrictCountyId: "",
        consignee: "",
        fuMobile: "",
        fuDistrictCountyId: "",
        forwardingUnit: "",
        arbitrationClause: "",
        specificProvisions: "",
        count: "",
        whetherThreeYear: null,
        priceMechanismClause: "",
        priceType: "",
        basePrice: "",
        priceMechanism: "",
        deliveryMethod: "",
        industryType: "",
        endDate: "",
        typeOfShipping: "",
        contractForm: "",
        startDate: "",
        contractType: "",
        positionId: "",
        signDate: "",
        carrier: "",
        buyerName: "",
        Seller: "",
        sellerName: "",
        templeteId: "",
        letterUrl: "",
        settlementUrl: "",
        settleUrl: "",
        settlementName: "",
      },
      InformationformRules: {
        name: [{ required: true, message: "请输入煤矿名称", trigger: "blur" }],
        districtCountyId: [
          { required: true, message: "请用煤省份", trigger: "blur" },
        ],
        count: [{ required: true, message: "请输入用煤数量", trigger: "blur" }],
      },
      CoalformRlues: {
        name: [{ required: true, message: "请输入煤矿名称", trigger: "blur" }],
        number: [
          { required: true, message: "请输入煤矿数量", trigger: "blur" },
        ],
      },
      TransportationformRu: {
        departure: [{ required: true, message: "请输入发站", trigger: "blur" }],
        arrive: [{ required: true, message: "请输入到站", trigger: "blur" }],
        port: [{ required: true, message: "请输入港口", trigger: "blur" }],
        description: [
          { required: true, message: "请输入规格", trigger: "blur" },
        ],
        subtotal: [{ required: true, message: "请输入小计", trigger: "blur" }],
        januay: [
          { required: true, message: "请输入一月数量", trigger: "blur" },
        ],
        february: [
          { required: true, message: "请输入二月数量", trigger: "blur" },
        ],
        march: [{ required: true, message: "请输入三月数量", trigger: "blur" }],
        april: [{ required: true, message: "请输入四月数量", trigger: "blur" }],
        may: [{ required: true, message: "请输入五月数量", trigger: "blur" }],
        june: [{ required: true, message: "请输入六月数量", trigger: "blur" }],
        july: [{ required: true, message: "请输入七月数量", trigger: "blur" }],
        august: [
          { required: true, message: "请输入八月数量", trigger: "blur" },
        ],
        september: [
          { required: true, message: "请输入九月数量", trigger: "blur" },
        ],
        october: [
          { required: true, message: "请输入十月数量", trigger: "blur" },
        ],
        november: [
          { required: true, message: "请输入十一月数量", trigger: "blur" },
        ],
        december: [
          { required: true, message: "请输入十二月数量", trigger: "blur" },
        ],
      },
      rules: {
        settleName: [
          { required: true, message: "请上传结算单据", trigger: "blur" },
        ],
        settlementName: [
          { required: true, message: "请上传交收单据", trigger: "blur" },
        ],
        letterName: [
          { required: true, message: "请上传履约承诺书", trigger: "blur" },
        ],
        offlineName: [
          { required: true, message: "请上传线下已签订合同", trigger: "blur" },
        ],
        sellerName: [
          { required: true, message: "请输入出卖人", trigger: "blur" },
        ],
        buyerName: [
          { required: true, message: "请输入买受人", trigger: "blur" },
        ],
        carrier: [{ required: true, message: "请输入承运人", trigger: "blur" }],
        signDate: [
          { required: true, message: "请选择合同签订日期", trigger: "change" },
        ],
        positionId: [
          { required: true, message: "请选择合同签订地点", trigger: "change" },
        ],
        contractType: [
          { required: true, message: "请选择合同类型", trigger: "change" },
        ],
        startDate: [
          { required: true, message: "请选择合同起始日期", trigger: "change" },
        ],
        contractForm: [
          { required: true, message: "请选择合同形式", trigger: "change" },
        ],
        endDate: [
          { required: true, message: "请选择合同结束日期", trigger: "change" },
        ],
        industryType: [
          { required: true, message: "请选择所属行业", trigger: "change" },
        ],
        deliveryMethod: [
          { required: true, message: "请选择交货方式", trigger: "change" },
        ],
        priceMechanism: [
          { required: true, message: "请选择合同价格机制", trigger: "change" },
        ],
        basePrice: [
          { required: true, message: "请输入合同基础价格", trigger: "blur" },
        ],
        priceType: [
          { required: true, message: "请选择价格类型", trigger: "change" },
        ],
        priceMechanismClause: [
          {
            required: true,
            message: "请输入价格机制具体条款",
            trigger: "blur",
          },
        ],
        whetherThreeYear: [
          {
            required: true,
            message: "请选择是否为3年及以上中长期合同",
            trigger: "change",
          },
        ],
        count: [{ required: true, message: "请输入合同总量", trigger: "blur" }],
        specificProvisions: [
          {
            required: true,
            message: "请输入欠一补三具体条款",
            trigger: "blur",
          },
        ],
        arbitrationClause: [
          { required: true, message: "请输入仲裁条款", trigger: "blur" },
        ],
        fuDistrictCountyId: [
          { required: true, message: "请选择发货地址", trigger: "change" },
        ],
        fuMobile: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
        consignee: [
          { required: true, message: "请输入收货单位", trigger: "blur" },
        ],
        coDistrictCountyId: [
          { required: true, message: "请选择收货地址", trigger: "change" },
        ],
        coMobile: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
        forwardingUnit: [
          { required: true, message: "请输入发货单位", trigger: "blur" },
        ],
      },
      Coalform: {
        name: "",
        number: "",
        id: "",
        sum: 0,
      },
      Transportationform: {
        arrive: "",
        departure: "",
        port: "",
        description: "",
        subtotal: "",
        januay: "",
        february: "",
        march: "",
        april: "",
        may: "",
        june: "",
        july: "",
        august: "",
        september: "",
        october: "",
        november: "",
        december: "",
      },
      Transportationoptions: [],
      dialogFormVisible: false,
      fileList: [],
      coMobileVile: false,
      Informationform: {
        sum: 0,
        enterpriseId: "",
        name: "",
        count: "",
        districtCountyId: "",
        districtCountyName: "",
      },
      restaurants: [],
      state: "",
      restaurants: [],
      state: "",
      timeout: null,
      templeteIdOptions: [],
      newarr: [],
      newarrs: [],
      istrue: false,
      no:'',
    };
  },
  // keep-live缓存记录
  // beforeRouteEnter(to, from, next) {
  //   console.log(from.path);
  //   if (from.path == "/AddTemplate") {
  //     to.meta.isBack = true;
  //   } else {
  //     to.meta.isBack = false;
  //   }
  //   next();
  // },
  mounted() {
    this.getTransportationoptions();
    this.getuserList();
    let id = this.$route.query.id;
    let type = this.$route.query.type;
    if (type == 1) {
      this.istrue = true;
    }
    if (id) {
      this.getContract(id);
    } else {
      this.ruleForm.sellerId = window.localStorage.getItem("userId");
      this.ruleForm.sellerName = window.localStorage.getItem("userName");
      this.getContractNos()
      console.log("--------");
    }
  },
  methods: {
    getContractNos(){
      getContractNo().then(res=>{
        if(res.code==0){
          this.no=res.data
        }
      })
    },
    coDistrictCountyIdChange(e) {
      this.ruleForm.coDistrictCountyId = e[2];
    },
    fuDistrictCountyIdChange(e) {
      this.ruleForm.fuDistrictCountyId = e[2];
    },
    positionIdChange(e) {
      this.ruleForm.positionId = e[2];
    },
    districtCountyIdChange(e) {
      this.$nextTick(() => {
        this.Informationform.districtCountyName =
          this.$refs["areaNames"].presentText;
      });
      console.log(e);
      this.Informationform.districtCountyId = e[2];
    },
    //下载履约承诺书模板
    XZClick() {
      let config = {
        type: 1,
        no:this.no
      };
      getFileIdByType(config).then((res) => {
        if (res.code == 0) {
          let id = res.data.id;
          let newurl = this.baseUrl + "third/third/ossGetFile4/" + id;
          const a = document.createElement("a");
          // 给a标签的href属性值加上地址,注意:这里是绝对路径,不用加 点.
          a.href = newurl;
          // 设置下载文件文件名,这里加上.xlsx指定文件类型,pdf文件就指定.fpd即可
          // a.download = row.title;
          // 障眼法藏起来a标签
          a.style.display = "none";
          // 将a标签追加到文档对象中
          document.body.appendChild(a);
          // 模拟点击了<a>标签,会触发<a>标签的href的读取,浏览器就会自动下载了
          a.click();
          // 一次性的,用完就删除a标签
          a.remove();
        }
      });
    },
    handleClick(index, rows, row) {
      console.log(index, rows, row);
      rows.splice(index, 1);
      this.Coalform.sum = floatCompute.floatSub(this.Coalform.sum, row.number);
      // this.Coalform.sum = this.Coalform.sum - Number(row.number);
    },
    handleClicks(index, rows, row) {
      console.log(index, rows, row);
      rows.splice(index, 1);
      this.Informationform.sum = floatCompute.floatSub(
        this.Informationform.sum - row.count
      );
    },
    handleClickss(index, rows, row) {
      rows.splice(index, 1);
    },
    countInput(type) {
      let config = {
        april: this.Transportationform.april,
        august: this.Transportationform.august,
        december: this.Transportationform.december,
        february: this.Transportationform.february,
        januay: this.Transportationform.januay,
        july: this.Transportationform.july,
        june: this.Transportationform.june,
        march: this.Transportationform.march,
        may: this.Transportationform.may,
        november: this.Transportationform.november,
        october: this.Transportationform.october,
        september: this.Transportationform.september,
        subtotal: this.Transportationform.subtotal,
        type: type,
      };
      executeMonthCount(config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.Transportationform.april = res.data.april;
          this.Transportationform.august = res.data.august;
          this.Transportationform.december = res.data.december;
          this.Transportationform.february = res.data.february;
          this.Transportationform.januay = res.data.januay;
          this.Transportationform.july = res.data.july;
          this.Transportationform.june = res.data.june;
          this.Transportationform.march = res.data.march;
          this.Transportationform.may = res.data.may;
          this.Transportationform.november = res.data.november;
          this.Transportationform.october = res.data.october;
          this.Transportationform.september = res.data.september;
          this.Transportationform.subtotal = res.data.subtotal;
        }
      });
    },

    getContract(id) {
      getContractInfo(id).then((res) => {
        if (res.code == 0) {
          this.ruleForm = res.data;
          this.contractCoalDTOList = res.data.contractCoalDTOList;
          this.coalEnterprisesDTOS = res.data.coalEnterprisesDTOS;
          this.contractOtherInfoDTO = res.data.contractOtherInfoDTO || [];
          // this.ruleForm.Seller = res.data.sellerName;
          // this.ruleForm.sellerId = res.data.sellerId;
          this.ruleForm.sellerName = res.data.sellerName;
          this.ruleForm.buyerName = res.data.buyerName;
          this.ruleForm.positionId = res.data.positionId;
          let lists = res.data.coalEnterprisesDTOS;
          let newlists = [];
          let totles = "";
          for (let i = 0; i < lists.length; i++) {
            newlists.push(Number(lists[i].count));
          }
          // console.log(newlists);
          // for (var i = 0; i < newlists.length; i++) {
          //   totles += newlists[i];
          // }
          if (totles == "") {
            totles = 0;
          }
          this.Informationform.sum = newlists.reduce(function (a, b) {
            return floatCompute.floatAdd(Number(a) + Number(b));
          }, 0);

          let list = res.data.contractCoalDTOList;
          let newlist = [];
          let totle = "";
          for (let i = 0; i < list.length; i++) {
            newlist.push(Number(list[i].number));
          }
          // for (var i = 0; i < newlist.length; i++) {
          //   if (!isNaN(newlist[i])) {
          //     totle += newlist[i];
          //   }
          // }
          if (totle == "") {
            totle = 0;
          }
          this.Coalform.sum = newlist.reduce(function (a, b) {
            return floatCompute.floatAdd(Number(a) + Number(b));
          }, 0);
        }
      });
    },
    sum(arr) {
      var len = arr.length;
      if (len == 0) {
        return 0;
      } else if (len == 1) {
        return arr[0];
      } else {
        return arr[0] + sum(arr.slice(1));
      }
    },
    VisiChange(e) {
      console.log(e);
      if (e) {
        this.getTransportationoptions();
      }
    },
    getTransportationoptions() {
      findVarietiesAndType(2).then((res) => {
        if (res.code == 0) {
          console.log(res);
          this.Transportationoptions = res.data;
        }
      });
    },
    getuserList() {
      let config = {
        pageNum: 1,
        pageSize: 99999,
      };
      findContractTemplateList(config).then((res) => {
        if (res.code == 0) {
          this.templeteIdOptions = res.data.list;
        }
      });
      getTreeV2().then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.citys = this.deleteChildren(res.data);
          // this.citys = res.data;
        }
      });
    },
    deleteChildren(arr) {
      let childs = arr;
      for (let i = childs.length; i--; i > 0) {
        if (childs[i].children) {
          if (childs[i].children.length) {
            this.deleteChildren(childs[i].children);
          } else {
            delete childs[i].children;
          }
        }
      }
      return arr;
    },
    //收获单位
    ConsigneeAsync(queryString, callback) {
      let config = {
        pageNum: 1,
        pageSize: 10000,
        userType: 2,
        keyWord: this.ruleForm.consignee,
      };
      var list = [];
      userList(config).then((res) => {
        if (res.code == 0) {
          for (let i of res.data.list) {
            i.value = i.realName; //将想要展示的数据作为value
          }
          list = res.data.list;
          callback(list);
        }
      });
    },
    ConsigneeSelect(item) {
      console.log(item);
      this.ruleForm.consignee = item.realName;
      this.$refs.ruleForm.validateField("consignee");
    },
    //发货单位
    dingUnitAsync(queryString, callback) {
      let config = {
        pageNum: 1,
        pageSize: 10000,
        userType: 2,
        keyWord: this.ruleForm.forwardingUnit,
      };
      var list = [];
      userList(config).then((res) => {
        if (res.code == 0) {
          for (let i of res.data.list) {
            i.value = i.realName; //将想要展示的数据作为value
          }
          list = res.data.list;
          callback(list);
        }
      });
    },
    dingUnitAsyncSelect(item) {
      console.log(item);
      this.ruleForm.forwardingUnit = item.realName;
      this.$refs.ruleForm.validateField("forwardingUnit");
      console.log(this.ruleForm.forwardingUnit);
    },
    // 买受人
    querySearchAsyncs(queryString, callback) {
      let config = {
        pageNum: 1,
        pageSize: 10000,
        userType: 2,
        keyWord: this.ruleForm.buyerName,
      };
      var list = [];
      userList(config).then((res) => {
        if (res.code == 0) {
          for (let i of res.data.list) {
            i.value = i.realName; //将想要展示的数据作为value
          }
          list = res.data.list;
          callback(list);
        }
      });
    },
    handleSelects(item) {
      console.log(item);
      this.ruleForm.buyerName = item.realName;
      this.ruleForm.buyerId = item.userId;
      this.$refs.ruleForm.validateField("buyerName");
    },
    //煤矿列表
    CoalformF(queryString, callback) {
      let config = {
        pageNum: 1,
        pageSize: 999999999,
        name: this.Coalform.name,
      };
      let list = [];
      findCoalMineList(config).then((res) => {
        if (res.code == 0) {
          for (let i of res.data.list) {
            i.value = i.name; //将想要展示的数据作为value
          }
          list = res.data.list;
          callback(list);
        }
      });
    },
    //用煤企业名称
    InformationformF(queryString, callback) {
      let config = {
        pageNum: 1,
        pageSize: 10000,
        userType: 2,
        keyWord: this.Informationform.name,
      };
      let list = [];
      userList(config).then((res) => {
        if (res.code == 0) {
          for (let i of res.data.list) {
            i.value = i.realName; //将想要展示的数据作为value
          }
          list = res.data.list;
          callback(list);
        }
      });
    },
    //受卖人
    querySearchAsync(queryString, callback) {
      let config = {
        pageNum: 1,
        pageSize: 10000,
        userType: 2,
        keyWord: this.ruleForm.sellerName,
      };
      var list = [];
      userList(config).then((res) => {
        if (res.code == 0) {
          for (let i of res.data.list) {
            i.value = i.realName; //将想要展示的数据作为value
          }
          list = res.data.list;
          callback(list);
        }
      });
    },
    handleSelect(item) {
      this.ruleForm.sellerId = item.userId;
      this.ruleForm.sellerName = item.realName;
    },
    handleSelectForm(item) {
      console.log(item);
      this.Informationform.enterpriseId = item.userId;
      this.Informationform.name = item.realName;
      this.$refs.Informationform.validateField("name");
      console.log(this.Informationform);
    },
    CoalformSelectForm(item) {
      console.log(item);
      this.Coalform.name = item.name;
      this.Coalform.id = item.id;
      this.$refs.Coalform.validateField("name");
    },
    addTable() {
      this.Transportation = true;
      this.Transportationform = {
        arrive: "",
        departure: "",
        port: "",
        description: "",
        subtotal: "",
        januay: "",
        february: "",
        march: "",
        april: "",
        may: "",
        june: "",
        july: "",
        august: "",
        september: "",
        october: "",
        november: "",
        december: "",
      };
    },

    submitForm(formName) {
      let a = Date.parse(this.ruleForm.startDate);
      let b = Date.parse(this.ruleForm.endDate);
      console.log(a < b);
      if (a > b || a == b) {
        this.$message.error("请选择正确的合同起始和结束日期");
        return false;
      }
      // ruleForm
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let id = this.$route.query.id;
          if (this.contractCoalDTOList.length == 0) {
            this.$message.error("请添加煤源信息");
            return false;
          }
          if (this.coalEnterprisesDTOS.length == 0) {
            this.$message.error("请添加用煤企业信息");
            return false;
          }
          if (this.contractOtherInfoDTO.length == 0) {
            this.$message.error("请添加2022年度合同数量、品种、运输信息");
            return false;
          }
          let config = {
            id: id,
            coalEnterprisesDTOS: this.coalEnterprisesDTOS,
            contractCoalDTOList: this.contractCoalDTOList,
            contractOtherInfoDTO: this.contractOtherInfoDTO,
            arbitrationClause: this.ruleForm.arbitrationClause,
            basePrice: this.ruleForm.basePrice,
            buyerId: this.ruleForm.buyerId,
            carrier: this.ruleForm.carrier,
            startDate: this.ruleForm.startDate,
            coDistrictCountyId: this.ruleForm.coDistrictCountyId,
            coMobile: this.ruleForm.coMobile,
            consignee: this.ruleForm.consignee,
            contractForm: this.ruleForm.contractForm,
            contractType: this.ruleForm.contractType,
            count: this.ruleForm.count,
            deliveryMethod: this.ruleForm.deliveryMethod,
            endDate: this.ruleForm.endDate,
            forwardingUnit: this.ruleForm.forwardingUnit,
            fuDistrictCountyId: this.ruleForm.fuDistrictCountyId,
            fuMobile: this.ruleForm.fuMobile,
            industryType: this.ruleForm.industryType,
            letterUrl: this.ruleForm.letterUrl,
            sellerName: this.ruleForm.sellerName,
            sellerId: this.ruleForm.sellerId,
            positionId: this.ruleForm.positionId,
            settlementName: this.ruleForm.settlementName,
            settlementUrl: this.ruleForm.settlementUrl,
            settleName: this.ruleForm.settleName,
            settleUrl: this.ruleForm.settleUrl,
            signDate: this.ruleForm.signDate,
            priceMechanism: this.ruleForm.priceMechanism,
            priceType: this.ruleForm.priceType,
            priceMechanismClause: this.ruleForm.priceMechanismClause,
            whetherThreeYear: this.ruleForm.whetherThreeYear,
            specificProvisions: this.ruleForm.specificProvisions,
            specificProvisionsName: this.ruleForm.specificProvisionsName,
            specificProvisionsUrl: this.ruleForm.specificProvisionsUrl,
            letterName: this.ruleForm.letterName,
            letterUrl: this.ruleForm.letterUrl,
            typeOfShipping: this.ruleForm.typeOfShipping,
            offlineName: this.ruleForm.offlineName,
            offlineUrl: this.ruleForm.offlineUrl,
            buyerName: this.ruleForm.buyerName,
            buyerId: this.ruleForm.buyerId,
            templeteId: this.ruleForm.templeteId,
            no:this.no
          };
          addAndEditContract(config).then((res) => {
            console.log(res);
            if (res.code == 0) {
              this.$message({
                message: "提交成功",
                type: "success",
              });
              this.$router.go(-1);
            } else {
              this.$message({
                message: res.message,
                type: "error",
              });
            }
          });
        }
      });
    },

    resetForm() {
      this.$router.go(-1);
    },
    SigningClick() {
      this.$confirm("确定签订履约承诺书嘛?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "签订成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    AddTemplateClick() {
      this.$router.push({
        path: "/AddTemplate",
      });
    },
    addsource() {
      console.log(this.tableData);
      this.dialogFormVisible = !this.dialogFormVisible;
      this.Coalform = {
        name: "",
        number: "",
      };
    },
    dialogFormVisibleDe() {
      this.dialogFormVisible = false;
      // let sum = 0;
      // for (let i = 0; i < this.newarrs.length; i++) {
      //   sum += Number(this.newarrs[i]);
      // }
      console.log(sum);
      // this.Coalform.sum = sum;
    },
    AddCoalform(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            id: this.Coalform.id,
            name: this.Coalform.name,
            number: this.Coalform.number,
          };
          this.contractCoalDTOList.push(obj);

          let list = this.contractCoalDTOList;
          let newlist = [];
          let totle = "";
          for (let i = 0; i < list.length; i++) {
            newlist.push(list[i].number);
          }
          if (totle == "") {
            totle = 0;
          }
          this.Coalform.sum = newlist.reduce(function (a, b) {
            return floatCompute.floatAdd(Number(a) + Number(b));
          }, 0);

          // this.newarrs.push(this.Coalform.number);
          // let sum = 0;
          // for (let i = 0; i < this.newarrs.length; i++) {
          //   sum += Number(this.newarrs[i]);
          // }
          // console.log(sum);
          // this.Coalform.sum = sum;

          this.dialogFormVisible = false;
          this.Coalform.name = "";
          this.Coalform.number = "";
          this.Coalform.id = "";
        }
      });
    },
    enterprises() {
      this.Informationform = {
        name: "",
        count: "",
        districtCountyId: "",
      };
      this.coMobileVile = !this.coMobileVile;
    },
    limitInput(value, name) {
      let val = (value && value.split("")) || [];
      let reg1 = /\d/;
      let reg2 = /\./;
      // 第一个字符不能为小数点
      if (val[0] == ".") {
        this.price = "";
        return;
      }
      // 过滤掉除数字和小数点外的字符
      val = val.filter((e) => reg1.test(e) || reg2.test(e));
      // 匹配小数点后只能有两位小数
      // 解释一下这个match正则规格
      // ^\d* 是指以数字开头，后面允许输入0到多位数字
      // (\.?) 是指只允许一个小数点
      // \d{0,2} 是指只允许0到2位小数
      if (name == "Coalform.number") {
        this.Coalform.number =
          val.join("").match(/^\d*(\.?\d{0,2})/g)[0] || null;
      }
      if (name == "Informationform.count") {
        this.Informationform.count =
          val.join("").match(/^\d*(\.?\d{0,2})/g)[0] || null;
      }
    },
    coMobileVileDe() {
      this.coMobileVile = false;
      console.log(this.Informationform.sum, this.newarr);
      let sum = 0;
      for (let i = 0; i < this.newarr.length; i++) {
        sum += Number(this.newarr[i]);
      }
      this.Informationform.sum = sum;
    },
    AddInformation(formName) {
      console.log(this.Informationform.count);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            name: this.Informationform.name,
            count: this.Informationform.count,
            districtCountyId: this.Informationform.districtCountyId,
            districtCountyName: this.Informationform.districtCountyName,
          };
          this.coalEnterprisesDTOS.push(obj);
          let list = this.coalEnterprisesDTOS;
          let newlist = [];
          let totle = "";
          for (let i = 0; i < list.length; i++) {
            newlist.push(list[i].count);
          }
          if (totle == "") {
            totle = 0;
          }
          this.Informationform.sum = newlist.reduce(function (a, b) {
            return floatCompute.floatAdd(Number(a) + Number(b));
          }, 0);
          this.coMobileVile = false;
          this.Informationform.name = "";
          this.Informationform.count = "";
          this.Informationform.districtCountyId = "";
        }
      });
    },
    TransportationClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            april: this.Transportationform.april,
            arrive: this.Transportationform.arrive,
            august: this.Transportationform.august,
            december: this.Transportationform.december,
            departure: this.Transportationform.departure,
            description: this.Transportationform.description,
            february: this.Transportationform.february,
            januay: this.Transportationform.januay,
            july: this.Transportationform.july,
            june: this.Transportationform.june,
            march: this.Transportationform.march,
            may: this.Transportationform.may,
            november: this.Transportationform.november,
            october: this.Transportationform.october,
            port: this.Transportationform.port,
            september: this.Transportationform.september,
            subtotal: this.Transportationform.subtotal,
          };
          console.log(this.contractOtherInfoDTO);
          this.contractOtherInfoDTO.push(obj);
          this.Transportation = false;
          this.Transportationform = {
            arrive: "",
            departure: "",
            port: "",
            description: "",
            subtotal: "",
            januay: "",
            february: "",
            march: "",
            april: "",
            may: "",
            june: "",
            july: "",
            august: "",
            september: "",
            october: "",
            november: "",
            december: "",
          };
        }
      });
    },
    handleChange() {},
    handleImageBeforeUp(file) {
      // let xls = file.name.slice(-3);
      // let pdf = file.name.slice(-4);
      // let isJPG;
      // if (pdf === "docx") {
      //   isJPG = true;
      // } else {
      //   isJPG = false;
      // }
      let otherFiles = file;
      // FormData 对象
      var formData = new FormData();
      // 文件对象
      // let name = file.name.split(".")[0];
      formData.append("file", otherFiles);
      // if (isJPG) {
      ossUploadFiletwo(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.ruleForm.letterUrl = res.data.fileId;
          this.ruleForm.letterName = res.data.originalName;
        }
      });
      // }else{
      //   this.$message.error('请上传格式为：docx的合同文件');
      // }
    },
    handleImageBeforeUploads(file) {
      // let xls = file.name.slice(-3);
      // let pdf = file.name.slice(-4);
      // let isJPG;
      // if (pdf === "docx") {
      //   isJPG = true;
      // } else {
      //   isJPG = false;
      // }
      let otherFiles = file;
      // FormData 对象
      var formData = new FormData();
      // 文件对象
      // let name = file.name.split(".")[0];
      formData.append("file", otherFiles);
      // if (isJPG) {
      ossUploadFiletwo(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.ruleForm.specificProvisionsUrl = res.data.fileId;
          this.ruleForm.specificProvisionsName = res.data.originalName;
        }
      });
      // }else{
      //   this.$message.error('请上传格式为：docx的合同文件');
      // }
    },
    settleUpload(file) {
      let otherFiles = file;
      // FormData 对象
      var formData = new FormData();
      // 文件对象
      // let name = file.name.split(".")[0];
      formData.append("file", otherFiles);
      // if (isJPG) {
      ossUploadFiletwo(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.ruleForm.settleUrl = res.data.fileId;
          this.ruleForm.settleName = res.data.originalName;
        }
      });
    },
    offlineUpload(file) {
      let otherFiles = file;
      // FormData 对象
      var formData = new FormData();
      // 文件对象
      // let name = file.name.split(".")[0];
      formData.append("file", otherFiles);
      // if (isJPG) {
      ossUploadFiletwo(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.ruleForm.settlementUrl = res.data.fileId;
          this.ruleForm.settlementName = res.data.originalName;
        }
      });
    },
    handleImageBeforeUpload(file) {
      // let xls = file.name.slice(-3);
      // let pdf = file.name.slice(-4);
      // let isJPG;
      // if (pdf === "docx") {
      //   isJPG = true;
      // } else {
      //   isJPG = false;
      // }
      let otherFiles = file;
      // FormData 对象
      var formData = new FormData();
      // 文件对象
      // let name = file.name.split(".")[0];
      formData.append("file", otherFiles);
      // if (isJPG) {
      ossUploadFiletwo(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.ruleForm.offlineUrl = res.data.fileId;
          this.ruleForm.offlineName = res.data.originalName;
        }
      });
      // }else{
      //   this.$message.error('请上传格式为：docx的合同文件');
      // }
    },
    handleExceed() {},
    handleRemove() {},
    beforeRemove() {},
    handlePreview() {},
  },
};
</script>

<style lang="scss" scoped>
.CoalQuantity {
  text-align: center;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}
.btn_size {
  height: 32px;
  margin-top: 5px;
}
.addenter {
  width: 1196px;
  margin: 0 auto;
  display: flex;
  .conter {
    width: 100%;
    border: 1px solid #ccc;
    background: #fff;
    padding: 20px;
    .conter_o {
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
    }
    .titlt {
      font-size: 20px;
      font-weight: bold;
    }
  }
}
.flex {
  display: flex;
  justify-content: space-between;
}

.demo-ruleForm {
  margin-top: 20px;
}
::v-deep .el-form-item__content {
  display: flex;
}
.flexs {
  display: flex;
}
.remarks {
  text-align: right;
  margin-top: 10px;
}
.conter_t {
  margin-top: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
}
.conter_f {
  margin-top: 20px;
}
.tab2 {
  input {
    width: 80px;
  }
}
.btn {
  text-align: center;
  margin: 30px auto 0;
}
::v-deep .casca {
  .el-input__inner {
    border: none;
  }
}
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
.el-button + .el-button,
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}
</style>